import { Grid, GridItem, HStack, Link, Text } from '@chakra-ui/react';
import { BaseAvatar, BaseTooltip, useAuth } from '@cksoftware/react-base';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import { WorkflowGroupActionModel, WorkflowGroupActionType, WorkflowGroupUserModel } from './Types';
import { getInitials, LinkButton } from './Utils';
import { WorkflowActionButton } from './WorkflowActionButton.tsx';
import { WorkflowContext } from './WorkflowProvider';
import { UserStatus } from 'src/enums/common/UserStatus';
import useTimer from 'src-new/util/hooks/useTimer';

dayjs.extend(durationPlugin);

export interface WorkflowUserProps {
  user: WorkflowGroupUserModel;
  actions: WorkflowGroupActionModel[];
  inboxManagerId?: string;
  inboxManager?: string;
}

export function WorkflowUser({ user, actions, inboxManagerId }: WorkflowUserProps) {
  const [model, invoke] = useContext(WorkflowContext);
  const timer = useTimer({ increment: true, startTime: user.CurrentStatus.StatusChangedOn });
  const awayTimer = useTimer({ increment: false, startTime: user.CurrentStatus.StatusReturnTime });
  const [bgColor, setBgColor] = useState(
    `WorkflowGroupActionType.${WorkflowGroupActionType[user.CurrentStatus.AssignedActionType]}`
  );
  const auth = useAuth();

  const amInboxCoordinator = useMemo(() => {
    return auth.user?.UserId == inboxManagerId;
  }, [auth.user?.UserId, inboxManagerId]);

  useEffect(() => {
    if (amInboxCoordinator && timer.minutes() >= 20) {
      setBgColor(
        bgColor != `WorkflowGroupActionType.${WorkflowGroupActionType[WorkflowGroupActionType.Status]}`
          ? `WorkflowGroupActionType.${WorkflowGroupActionType[WorkflowGroupActionType.Status]}`
          : `WorkflowGroupActionType.${WorkflowGroupActionType[user.CurrentStatus.AssignedActionType]}`
      );
    } else {
      setBgColor(`WorkflowGroupActionType.${WorkflowGroupActionType[user.CurrentStatus.AssignedActionType]}`);
    }
  }, [timer, user.CurrentStatus.AssignedActionType, amInboxCoordinator]);

  return (
    <Grid
      bg={bgColor}
      templateColumns={'repeat(4,1fr)'}
      templateRows={'1fr 1fr'}
      border={'1px solid black'}
      height={'52px'}
      p={1}
      width={amInboxCoordinator ? '250px' : '150px'}>
      <GridItem>
        <BaseTooltip content={user.Name}>
          <BaseAvatar
            size={'2xs'}
            textTransform={'none'}
            color={'white'}
            cursor={'grab'}
            name={user.Name}
            initials={getInitials(user.Name)}
            backgroundColor={`#${user.BubbleColor == null ? 'gray' : user.BubbleColor.replaceAll('#', '')}`}></BaseAvatar>
        </BaseTooltip>
      </GridItem>
      <GridItem colSpan={3}>
        <HStack gap={1}>
          {user.ActionTotal.map((total, index) => {
            const action = actions[index];
            if (action.Type == WorkflowGroupActionType.Assignment || amInboxCoordinator) {
              return (
                <WorkflowActionButton
                  action={action}
                  key={`${action.ActionName}-${user.UserId}`}
                  userId={user.UserId}
                  count={total}
                  user={user}
                  userStatus={user.CurrentStatus}
                />
              );
            }
            return null;
          })}
        </HStack>
      </GridItem>
      <GridItem colSpan={3} overflow={'clip'}>
        {user.CurrentStatus.Status == UserStatus.Away ? (
          <BaseTooltip content={user.CurrentStatus.StatusMessage}>
            <Text mt={1} fontSize={amInboxCoordinator ? 'xs' : '0.5em'} mb={0} lineHeight={1}>
              {`Returning at ${dayjs(user.CurrentStatus.StatusReturnTime).format('hh:mm')} (${awayTimer.format('m')}m)`}
            </Text>
          </BaseTooltip>
        ) : (
          <BaseTooltip content={''}>
            <Text fontSize={'xs'} mb={2} mt={1} width={amInboxCoordinator ? '23em' : '12em'} truncate={true}>
              {`${user.CurrentStatus.StatusMessage} ${amInboxCoordinator ? `(${timer.format('m')}m ${timer.format('s')}s)` : ''}`}
            </Text>
          </BaseTooltip>
        )}
      </GridItem>
      <GridItem ml={'auto'}>
        {auth.isInRole([ApplicationRoles.inboxCoordinator], false) && (
          <BaseTooltip content='Set Coordinator'>
            <Text as={'u'} fontSize={'0.6em'} userSelect={'none'}>
              <Link
                color='blue'
                onClick={() => {
                  // @ts-expect-error added by automation
                  invoke('SetInboxManager', user.UserId, user.Name, model.data.WorkflowGroupId);
                }}>
                Set
              </Link>
            </Text>
          </BaseTooltip>
        )}
        {amInboxCoordinator && (
          <LinkButton
            fontSize='0.6em'
            onClick={() => {
              // @ts-expect-error added by automation
              invoke('UndoLast', user, user.UserId, model.data.WorkflowGroupId);
            }}>
            Undo
          </LinkButton>
        )}
      </GridItem>
    </Grid>
  );
}
