import styled from '../../utils/styled/styled';

export const RedFontDiv = styled.div`
  color: ${(props) => props.theme.Red};
`;

const OrangeFontDiv = styled.div`
  color: ${(props) => props.theme.Orange};
`;

export const BoldRedFontDiv = styled(RedFontDiv)`
  font-weight: bold;
`;

export const BoldYellowFontDiv = styled(OrangeFontDiv)`
  font-weight: bold;
`;

export const RedFontSpan = styled.span`
  color: ${(props) => props.theme.Red};
`;

export const BoldRedFontSpan = styled(RedFontSpan)`
  font-weight: bold;
`;

interface IFontColourOverride {
  colorOverride?: string;
}

export const BlueFontDiv = styled.div<IFontColourOverride>`
  color: ${(props) => props.colorOverride ?? props.theme.Blue};
`;

const GreenFontDiv = styled.div<IFontColourOverride>`
  color: ${(props) => props.colorOverride ?? props.theme.Green};
`;

export const BoldGreenFontDiv = styled(GreenFontDiv)`
  font-weight: bold;
`;

export const BoldSpan = styled.span`
  font-weight: bold;
`;

export const BoldBlueFontDiv = styled(BlueFontDiv)`
  font-weight: bold;
`;

export const ColouredFontDiv = styled.div<IFontColourOverride>`
  color: ${(props) => props.colorOverride ?? 'unset'};
  white-space: pre-wrap;
`;

export const ColouredFontSpan = styled.span<IFontColourOverride>`
  color: ${(props) => props.colorOverride ?? 'unset'};
  white-space: pre-wrap;
`;

export const BlueFontSpan = styled.span<IFontColourOverride>`
  color: ${(props) => props.colorOverride ?? props.theme.Blue};
`;

export const ClickableBlueFont = styled(BlueFontSpan)`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const BoldBlueFontSpan = styled(BlueFontSpan)`
  font-weight: bold;
`;

export const TableIconContainer = styled.span`
  margin: 2px;
`;

export const CompanyInfoContainer = styled.div`
  padding: 10px;
  width: 400px;
  font-size: 0.85em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  background: white;
`;

export const GrowItem = styled.div`
  flex-grow: 1;
`;

export const StyledHeader = styled.h6`
  margin: 0;
  padding: 0;
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: 1px solid rgb(237, 235, 233);
  font-weight: 600;
  font-size: 14px;
`;

export const StyledOnHoverDiv = styled.div`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const UnderlineOnHoverDiv = styled.div`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const HovercardTextWrappingDiv = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
