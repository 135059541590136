import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { BaseAvatar, BaseButton, BaseTooltip, useAuth } from '@cksoftware/react-base';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import { useContext, useEffect, useState } from 'react';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import { WorkflowGroupActionType } from './Types';
import { getInitials, LinkButton } from './Utils';
import { WorkflowActionButton } from './WorkflowActionButton.tsx';
import { WorkflowContext } from './WorkflowProvider';
import { WorkflowUserProps } from './WorkflowUser';
import { FrontierUser } from 'src-new/globalModels/frontierUser.ts';

dayjs.extend(durationPlugin);

export function WorkflowCurrentUser({ user, inboxManager, actions }: WorkflowUserProps) {
  const [model, invoke] = useContext(WorkflowContext);
  const [time, setTime] = useState<durationPlugin.Duration>();
  const [retrigger, setRetrigger] = useState(false);
  const auth = useAuth<FrontierUser>();

  useEffect(() => {
    if (model.data?.CurrentUser?.WorkingHours) {
      const currentTime = dayjs();
      const endTime = dayjs(model.data?.CurrentUser?.WorkingHours?.EndDate);
      const diff = dayjs.duration(endTime.diff(currentTime, 'date'));
      if (time && time?.asSeconds() <= 0 && diff.asSeconds() > 0) {
        setRetrigger(!retrigger);
      }
      setTime(diff);
    }
  }, [model.data?.CurrentUser.WorkingHours?.EndDate]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((previous: any) => {
        const value = previous.subtract(1, 'second');
        if (value.asSeconds() <= 0) {
          // @ts-expect-error added by automation
          invoke?.('EndShift', model.data?.CurrentUser);
          clearInterval(timer);
        }
        return value.seconds() < 0 ? previous : value;
      });
    }, 1000);

    return () => {
      return clearInterval(timer);
    };
  }, [retrigger]);
  return (
    <VStack
      p={'4px'}
      gap={'4px'}
      m={0}
      bg={`WorkflowGroupActionType.${WorkflowGroupActionType[user.CurrentStatus.AssignedActionType]}`}
      border={'1px solid black'}
      height={'100%'}>
      <HStack align={'center'} w={'100%'} alignSelf={'flex-start'} justify={'space-between'}>
        <BaseAvatar
          size={'2xs'}
          color={'white'}
          textTransform={'none'}
          cursor={'grab'}
          initials={getInitials(user.Name)}
          name={user.Name}
          backgroundColor={user.BubbleColor}></BaseAvatar>

        <BaseTooltip content={`Time remaining in shift`} disabled={!time}>
          <Text as={'b'} fontSize={'xs'} width={'6em'} visibility={'hidden'}>
            {time && time.format('H:mm:ss')}
          </Text>
        </BaseTooltip>

        <Flex gap={0} flexDir={'column'}>
          <Box p={0} m={0} fontSize={'2xs'} fontWeight={'bold'} truncate>
            {inboxManager && `Coord: ${inboxManager}`.toUpperCase()}
          </Box>
          {auth.isInRole([ApplicationRoles.inboxCoordinator]) && inboxManager != auth.user?.Name && (
            <LinkButton
              fontSize='0.6em'
              onClick={() => {
                // @ts-expect-error added by automation
                invoke('SetInboxManager', user.UserId, user.Name, model.data.WorkflowGroupId);
              }}>
              Set Coordinator
            </LinkButton>
          )}
        </Flex>
      </HStack>
      <HStack gap={1}>
        {user.ActionTotal.map((total, index) => {
          const action = actions[index];
          return (
            <WorkflowActionButton
              action={action}
              key={`${action.ActionName}-${user.UserId}`}
              userId={user.UserId}
              count={total}
              user={user}
              userStatus={user.CurrentStatus}
            />
          );
        })}
      </HStack>
      <HStack w='100%' justify={'space-between'} pr='1' pl='2'>
        <BaseTooltip
          content={`${user.CurrentStatus.StatusMessage} ${
            user.CurrentStatus.StatusReturnTime
              ? ` returning at ${dayjs(user.CurrentStatus.StatusReturnTime).format('H:mm a')}`.toUpperCase()
              : ''
          }`}>
          <Text width={'6em'} fontSize={'xs'} textAlign={'center'} truncate maxW={'7em'} mb={0}>
            {user.CurrentStatus.StatusMessage}
          </Text>
        </BaseTooltip>
        {}
        <LinkButton
          fontSize='0.6em'
          onClick={() => {
            // @ts-expect-error added by automation
            invoke('UndoLast', user, user.UserId, model.data.WorkflowGroupId);
          }}>
          Undo
        </LinkButton>
        {time && time.asMinutes() < 30 && (
          <HStack gap={'0.2em'}>
            {[15, 30, 60].map((v) => (
              <BaseButton
                key={v}
                p='1'
                colorScheme={'blue'}
                minW={'fit-content'}
                size={'2xs'}
                borderRadius={'0'}
                onClick={() => {
                  // @ts-expect-error added by automation
                  invoke('ExtendWorkingHours', model.data?.CurrentUser, v);
                }}
                fontSize={'0.7em'}>
                {'+' + v + 'M'}
              </BaseButton>
            ))}
          </HStack>
        )}
      </HStack>
    </VStack>
  );
}
