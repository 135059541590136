import { Box, Flex } from '@chakra-ui/react';
import { BaseAvatar } from '@cksoftware/react-base';

type Props = {
  name: any;
};

export const SelectedTravellerPill = (props: Props) => {
  return (
    <Flex alignItems='center' justifyContent='center' gap={'4px'} padding={'0 4px'}>
      <BaseAvatar size={'2xs'} name={typeof props.name == 'string' ? props.name : ''} />
      <Box fontSize={'sm'}>{props.name}</Box>
    </Flex>
  );
};
