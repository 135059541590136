import { observer } from 'mobx-react-lite';
import React from 'react';
import { useUtilityStore } from '../../stores/useMobxStores';
import { Box, Center } from '@chakra-ui/react';
import { BaseLoadingSpinner } from '@cksoftware/react-base';

interface IOverlayLoadingSpinner {}

const OverlayLoadingSpinner: React.FC<IOverlayLoadingSpinner> = () => {
  const store = useUtilityStore();

  return (
    <>
      {store.spinnerState.IsOpen && (
        <Center pos={'absolute'} top={0} right={0} zIndex={'max'} w={'100vw'} h={'100vh'}>
          <Box bg={'white'} opacity={0.8} p={4}>
            <BaseLoadingSpinner />
          </Box>
        </Center>
      )}
    </>
  );
};

export default observer(OverlayLoadingSpinner);
