import { HStack, Stack } from '@chakra-ui/react';
import { createFieldHelper, FormContainer, renderFieldHelperInput, useHookForm } from '@cksoftware/react-base';
import { ApiEndpoints } from 'src-new/constants/apiEndpoints';
import { ActivityInformationTravellerDisplay } from './activityInformationTravellerDisplay';
import { SelectedTravellerPill } from 'src-new/features/activites/travellers/selectedTravellerPill';
import { HotelBookingType } from 'src-new/globalModels/common/hotelBookingType';
import { useWatch } from 'react-hook-form';

type TravellerSearchForm = { TravelerIds: number[]; CompanyId: number | undefined };
export const TravellerSearch = () => {
  const { formMethods } = useHookForm<TravellerSearchForm>({ TravelerIds: [], CompanyId: undefined });
  const [companyId, travellers] = useWatch({
    control: formMethods.control,
    name: ['CompanyId', 'TravelerIds']
  });

  const helper = createFieldHelper<TravellerSearchForm>(formMethods);
  const fields = [
    helper
      .selectInputQueryItems('CompanyId', { searchUrl: ApiEndpoints.selectOptions.companies.list })
      .withInputProps({ isClearable: true })
      .isNumeric(),
    helper
      .multiSelectQuerySearchItems('TravelerIds', {
        searchUrl: `${ApiEndpoints.selectOptions.companies.companyContactsSearch(companyId, false, HotelBookingType.Business)}`,
        enabled: companyId != undefined,
        minSearchChars: 1
      })
      .withInputProps({
        components: {
          MultiValueLabel: (props) => {
            return <SelectedTravellerPill name={props.data.Label} />;
          }
        }
      })
      .withInputProps({ isClearable: true })
      .withLabel('Travelers')
      .isNumeric()
  ];

  return (
    <Stack>
      <FormContainer methods={formMethods} onSubmit={() => {}} hideControls={true}>
        <HStack>
          {renderFieldHelperInput('CompanyId', fields)}
          {renderFieldHelperInput('TravelerIds', fields)}
        </HStack>
      </FormContainer>
      <ActivityInformationTravellerDisplay
        authorizedBookerId={undefined}
        approvedBookerId={undefined}
        travellerIds={travellers}
        displayCompany
      />
    </Stack>
  );
};
