import { HStack, Stack } from '@chakra-ui/react';
import { createFieldHelper, FormContainer, renderFieldHelperInput, useHookForm } from '@cksoftware/react-base';
import { ApiEndpoints } from 'src-new/constants/apiEndpoints';
import { ActivityInformationHotelDisplay } from './activityInformationHotelDisplay';
import { useWatch } from 'react-hook-form';

type HotelSearchForm = {
  HotelIds: number[];
  CityId?: number;
  HasSelectedCity: boolean;
};
export const HotelSearch = () => {
  const { formMethods } = useHookForm<HotelSearchForm>({ HotelIds: [], HasSelectedCity: false });
  const helper = createFieldHelper<HotelSearchForm>(formMethods);

  const [city, hotelIds] = useWatch({
    control: formMethods.control,
    name: ['CityId', 'HotelIds']
  });

  const fields = [
    helper
      .selectInputQuerySearchItems('CityId', {
        searchUrl: ApiEndpoints.selectOptions.cities(),
        minSearchChars: 3
      })
      .withInputProps({ isClearable: true })
      .withLabel('City Name'),

    helper
      .multiSelectQueryItems('HotelIds', {
        searchUrl: ApiEndpoints.selectOptions.hotels.hotelInCity(city ?? 0),
        enabled: city !== undefined && city > 0
      })
      .withInputProps({ isClearable: true })
  ];

  return (
    <Stack>
      <FormContainer methods={formMethods} onSubmit={() => {}} hideControls={true}>
        <HStack>
          {renderFieldHelperInput('CityId', fields)}
          {renderFieldHelperInput('HotelIds', fields)}
        </HStack>
      </FormContainer>
      <ActivityInformationHotelDisplay hotelIds={hotelIds ?? []} />
    </Stack>
  );
};
