import { ActivityInformationTravellerModel } from 'src-new/features/activites/informationDisplays/api/useActivityInformationTravellersQuery.ts';
import { useMemo } from 'react';
import { ContactInfoBar } from 'src-new/components/ContactInfoBar/ContactInfoBar.tsx';
import { EmailAddressType } from 'src/enums/crm/EmailAddressType.ts';

type Props = {
  contact: ActivityInformationTravellerModel;
};

export const ActivityInformationTravellerContactInfoBar = (props: Props) => {
  const convertedEmails = useMemo(() => {
    return [
      { Address: props.contact.Email, Description: 'Email Address', Type: EmailAddressType.Primary },
      { Address: props.contact.PersonalEmail, Description: 'Personal Email Address', Type: EmailAddressType.Personal }
    ].filter((val) => val.Address);
  }, [props.contact.Email, props.contact.PersonalEmail]);

  return <ContactInfoBar emails={convertedEmails} faxNumbers={[]} phoneNumbers={props.contact.PhoneNumbers} />;
};
