import {
  ActivityFooterOrder,
  ActivityFooterTabState,
  useActivityFooterStore
} from 'src-new/stores/activityFooterStore';
import { useActivityStatusQuery } from '../api/useActivityStatusQuery';
import { useHotelIdsFromStatus } from 'src-new/features/activites/informationDisplays/util/useHotelIdsFromStatus.ts';
import { useActivityInformationRenderSwitchesWithStatus } from './util/useActivityInformationRenderSwitches';
import { FooterTabsControlled, FooterTabType } from 'src-new/components/Modal/FooterTabs';
import { useContext, useMemo } from 'react';
import { HotelBookingType } from 'src-new/globalModels/common/hotelBookingType.ts';
import { ActivityInformationCompanyDisplay } from 'src-new/features/activites/informationDisplays/components/activityInformationCompanyDisplay.tsx';
import { Box } from '@chakra-ui/react';
import { ActivityInformationTravellerDisplay } from 'src-new/features/activites/informationDisplays/components/activityInformationTravellerDisplay.tsx';
import { ActivityInformationHotelDisplay } from 'src-new/features/activites/informationDisplays/components/activityInformationHotelDisplay.tsx';
import { CompanySearch } from 'src-new/features/activites/informationDisplays/components/CompanySearch.tsx';
import { HotelSearch } from 'src-new/features/activites/informationDisplays/components/HotelSearch.tsx';
import { TravellerSearch } from 'src-new/features/activites/informationDisplays/components/TravellerSearch.tsx';
import { ActivityInformationContext } from './activityInformationContext';

export const ActivityInformationFooter = () => {
  const context = useContext(ActivityInformationContext);

  const activityStatus = useActivityStatusQuery(context?.activityId);
  const store = useActivityFooterStore();

  const hotelIds = useHotelIdsFromStatus(activityStatus);
  const travellerIds = useMemo(
    () => activityStatus.data?.Travellers.flatMap((x) => x.Id) ?? [],
    [activityStatus.data?.Travellers]
  );

  const renderSwitches = useActivityInformationRenderSwitchesWithStatus(activityStatus.data, hotelIds);
  const tabs: FooterTabType[] = ActivityFooterOrder.flatMap((tab) => {
    switch (tab) {
      case ActivityFooterTabState.Company:
        return {
          header: 'Activity Company',
          disabled: !renderSwitches.renderPolicies,
          panel: renderSwitches.renderPolicies &&
            activityStatus.data?.CompanyId &&
            activityStatus.data?.HotelBookingType != HotelBookingType.Personal && (
              <ActivityInformationCompanyDisplay companyId={activityStatus.data?.CompanyId} />
            )
        };
      case ActivityFooterTabState.Travellers:
        return {
          header: 'Activity Travelers',
          disabled: !renderSwitches.renderTravellers,
          panel: renderSwitches.renderTravellers && (
            <Box>
              <ActivityInformationTravellerDisplay
                authorizedBookerId={activityStatus.data?.AuthorizedBookerId}
                approvedBookerId={activityStatus.data?.ApprovedBookerId}
                travellerIds={travellerIds}
              />
            </Box>
          )
        };
      case ActivityFooterTabState.HotelInfo:
        return {
          header: 'Activity Hotel',
          disabled: !renderSwitches.renderHotel,
          panel: renderSwitches.renderHotel && (
            <Box maxH={'90vh'}>
              <ActivityInformationHotelDisplay hotelIds={hotelIds ?? []} />
            </Box>
          )
        };
      case ActivityFooterTabState.CompanySearch:
        return {
          header: 'Company Search',
          panel: (
            <Box>
              <CompanySearch />
            </Box>
          )
        };
      case ActivityFooterTabState.HotelSearch:
        return {
          header: 'Hotel Search',
          panel: (
            <Box>
              <HotelSearch />
            </Box>
          )
        };
      case ActivityFooterTabState.TravellersSearch:
        return {
          header: 'Traveler Search',
          panel: (
            <Box>
              <TravellerSearch />
            </Box>
          )
        };
      default:
        return [];
    }
  });

  return (
    <>
      <FooterTabsControlled
        height={store.height}
        setHeight={store.setHeight}
        disclosure={{
          onOpen: store.onOpen,
          onClose: store.onClose,
          open: store.open,
          onToggle: store.onToggle,
          setOpen: store.setOpen
        }}
        index={store.index}
        setIndex={store.setIndex}
        tabs={tabs}
      />
    </>
  );
};
