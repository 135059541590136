import { CardHeader } from 'reactstrap';
import { Flex } from 'src/components/base/flex';
import styled from 'styled-components';

interface ILimitedGrowthWrapper {
  maxHeight: string | undefined;
}

export const LimitedGrowthWrapper = styled.div<ILimitedGrowthWrapper>`
  /* These next line gives the right panel a scroll bar along side the ScrollablePane. This is to keep the left panel in view at all times */
  max-height: ${(props) => `calc(${props.maxHeight})`};
  overflow: auto;
`;

export const StyledPDFViewer = styled.div`
  width: 100%;
  height: 750;
`;

export const StyledActivityQuoteIFrameContainer = styled.div`
  width: 100%;
  height: 400px;
  border: 1px solid black;
`;

export const StyledActivityQuoteIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

export const ActivityTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
  background-color: white;
`;
export const ActivityTitleOriginalFLSQ = styled.span`
  font-style: italic;
  font-size: 0.9rem;
`;
export const ActivityTitleCancelled = styled.span`
  font-weight: bold;
  color: red;
  font-size: 1.1rem;
`;
export const ActivityModalTitle = styled(ActivityTitle)`
  max-width: 75%;
`;

export const PaddedBox = styled.div`
  padding: 15px;
`;

export const StyledTd = styled.td`
  max-width: 230px;
  width: 230px;
`;

export const UrgentHeader = styled(CardHeader)`
  color: rgb(140 20 252);
  font-weight: bold;
  font-size: 1.15rem;
`;

export const ActivityHeader = styled(Flex)`
  height: 44px;
  margin-left: 25px;
  margin-right: 25px;
`;
export const ActivitySubHeader = styled(Flex)`
  gap: 10px;
`;
