import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ProfileStateStore = {
  dyslexicEnabled: boolean;
  setEnableDyslexia: (enable: boolean) => void;
};

export const useProfileStateStore = create(
  persist<ProfileStateStore>(
    (set) => ({
      dyslexicEnabled: false,
      setEnableDyslexia: (isEnabled) => set({ dyslexicEnabled: isEnabled })
    }),
    {
      name: 'dyslexic-state'
    }
  )
);
