import { observer } from 'mobx-react-lite';
import React from 'react';
import { Anchorme } from 'react-anchorme';
import { useNavigate } from 'react-router-dom';
import FaIcon from '../../components/common/FaIcon';
import FaIconWithToolTip from '../../components/common/FaIconWithToolTip';
import MailtoLink from '../../components/common/MailtoLink';
import PhoneDisplay from '../../components/common/PhoneDisplay';
import { PreWrapTextTbody } from '../../components/common/PreWrapTextDiv';
import applicationRoutes from '../../constants/applicationRoutes';
import AgentQuerySearchTravellerResultModel from '../../models/search/AgentQuerySearchTravellerResultModel';
import { useUtilityStore } from '../../stores/useMobxStores';
import theme from '../../theme';
import { getYesNoFromBoolean } from '../../utils/helpers/crmHelpers';
import { stringIsNullOrEmpty } from '../../utils/helpers/stringHelpers';
import { BoldSpan, ColouredFontDiv } from '../agentquery/styledComponents';
import styled from 'src/utils/styled/styled';
import { BaseCard } from '@cksoftware/react-base';
import { Table } from '@chakra-ui/react';

interface ITravellerResultTable {
  travellers: Array<AgentQuerySearchTravellerResultModel>;
  allowEditUser: boolean;
}

interface IStyledDiv {
  active: boolean;
}

const StyledDiv = styled.div<IStyledDiv>`
  color: ${(props) => !props.active && '#dc3545'};
`;
const BoldRedFont = styled.span`
  font-weight: bold;
  color: #dc3545;
  font-size: 16px;
`;

const TravellerResultTable: React.FC<ITravellerResultTable> = (props: ITravellerResultTable) => {
  const navigate = useNavigate();
  const utilStore = useUtilityStore();

  return (
    <div>
      {props.travellers?.length > 0 && (
        <BaseCard header={'Travellers'}>
          <Table.Root size='sm'>
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeader>
                  <BoldSpan>Personal Information: </BoldSpan>
                </Table.ColumnHeader>
                <Table.ColumnHeader>
                  <BoldSpan>Title/VIP: </BoldSpan>
                </Table.ColumnHeader>
                <Table.ColumnHeader>
                  <BoldSpan>Authorized Booker, Approvers Name & Branch Office: </BoldSpan>
                </Table.ColumnHeader>
                <Table.ColumnHeader>
                  <BoldSpan>Static Coding: </BoldSpan>
                </Table.ColumnHeader>
                <Table.ColumnHeader>
                  <BoldSpan>Loyalty Account Numbers: </BoldSpan>
                </Table.ColumnHeader>
                <Table.ColumnHeader>
                  <BoldSpan>Personal Preferences: </BoldSpan>
                </Table.ColumnHeader>
                <Table.ColumnHeader>
                  <BoldSpan>Notes: </BoldSpan>
                </Table.ColumnHeader>
                <Table.ColumnHeader>
                  <BoldSpan>Reporting Requirements: </BoldSpan>
                </Table.ColumnHeader>
                <Table.ColumnHeader>
                  <BoldSpan>Custom Fields: </BoldSpan>
                </Table.ColumnHeader>
              </Table.Row>
            </Table.Header>
            <PreWrapTextTbody>
              {props.travellers.map((travellerSearchResultModel, key) => {
                const traveller = travellerSearchResultModel.Traveller;
                return (
                  <Table.Row key={key}>
                    <Table.Cell>
                      {!traveller.Active && <BoldRedFont>InActive</BoldRedFont>}
                      <StyledDiv active={traveller.Active}>
                        Name: {traveller.FullName} &nbsp;
                        {props.allowEditUser && (
                          <FaIcon
                            clickable
                            onClick={() => {
                              navigate(
                                applicationRoutes.crm.company.edit +
                                  '/' +
                                  travellerSearchResultModel.Traveller.CompanyId +
                                  '?travellerId=' +
                                  travellerSearchResultModel.Traveller.Id
                              );
                              utilStore.modalState.close();
                            }}
                            iconName='fa-edit'
                          />
                        )}
                      </StyledDiv>
                      <StyledDiv active={traveller.Active}>
                        Company Name: {travellerSearchResultModel.CompanyName}
                      </StyledDiv>
                      <StyledDiv active={traveller.Active}>
                        <FaIcon iconName='fa-at' /> <MailtoLink address={traveller.Email} displayAddress={true} />
                      </StyledDiv>
                      <StyledDiv active={traveller.Active}>
                        <FaIconWithToolTip tooltip={'Mobile Phone'} iconName='fa-mobile' />{' '}
                        <PhoneDisplay number={traveller.MobilePhoneNumber} displayNumber={true} />
                      </StyledDiv>
                      {traveller.BusinessPhoneNumber && (
                        <StyledDiv active={traveller.Active}>
                          <FaIconWithToolTip tooltip={'Business Phone'} iconName='fa-phone-office' />{' '}
                          <PhoneDisplay number={traveller.BusinessPhoneNumber} displayNumber={true} />
                        </StyledDiv>
                      )}
                      <StyledDiv active={traveller.Active}>
                        <FaIconWithToolTip tooltip={'Office Phone'} iconName='fa-phone' />{' '}
                        <PhoneDisplay number={traveller.OfficePhoneNumber} displayNumber={true} />
                      </StyledDiv>
                    </Table.Cell>
                    <Table.Cell>
                      <StyledDiv active={traveller.Active}>Title: {traveller.ContactTitle}</StyledDiv>
                      <StyledDiv active={traveller.Active}>VIP: {getYesNoFromBoolean(traveller.IsVip)}</StyledDiv>
                    </Table.Cell>
                    <Table.Cell>
                      <StyledDiv active={traveller.Active}>
                        Authorized Booker: {getYesNoFromBoolean(traveller.AuthorizedBooker)}
                      </StyledDiv>
                      {!stringIsNullOrEmpty(traveller.AuthorizedBookerApproversName) && (
                        <StyledDiv active={traveller.Active}>
                          Approvers Name:&nbsp;
                          <Anchorme target='_blank'>{`Contact ${traveller.AuthorizedBookerApproversName}`}</Anchorme>
                        </StyledDiv>
                      )}
                      {traveller.BranchOffice != null && (
                        <StyledDiv active={traveller.Active}>
                          Branch Office:&nbsp;
                          <Anchorme target='_blank'>{traveller.BranchOffice.Name}</Anchorme>
                        </StyledDiv>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {traveller.ReportingRequirements.map((x, i) => (
                        <StyledDiv active={traveller.Active} key={i}>
                          {x.Example}
                        </StyledDiv>
                      ))}
                    </Table.Cell>
                    <Table.Cell>
                      {traveller.LoyaltyAccounts.map((x, i) => (
                        <StyledDiv active={traveller.Active} key={i}>
                          {x.DisplayString}
                        </StyledDiv>
                      ))}
                    </Table.Cell>
                    <Table.Cell>
                      <StyledDiv active={traveller.Active}>
                        <Anchorme target='_blank'>{traveller.PersonalPreferences}</Anchorme>
                      </StyledDiv>
                    </Table.Cell>
                    <Table.Cell>
                      <StyledDiv active={traveller.Active}>
                        <Anchorme target='_blank'>{traveller.Notes}</Anchorme>
                        {!stringIsNullOrEmpty(traveller.CodeRedNote) && (
                          <ColouredFontDiv colorOverride={theme.Red}>
                            <BoldSpan>Code Red: </BoldSpan>
                            <Anchorme target='_blank'>{traveller.CodeRedNote}</Anchorme>
                          </ColouredFontDiv>
                        )}
                      </StyledDiv>
                    </Table.Cell>
                    <Table.Cell>
                      <StyledDiv active={traveller.Active}>
                        <Anchorme target='_blank'>{travellerSearchResultModel.CodingRequirements}</Anchorme>
                      </StyledDiv>
                    </Table.Cell>
                    <Table.Cell>
                      {traveller.ContactCustomFields.map((x, i) => (
                        <div key={i}>
                          <BoldSpan>{x.Label}:</BoldSpan> <Anchorme target='_blank'>{x.Value}</Anchorme>
                        </div>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </PreWrapTextTbody>
          </Table.Root>
        </BaseCard>
      )}
    </div>
  );
};
export default observer(TravellerResultTable);
