import { Amenity } from '../../models/hotel/Amenity';
import Endpoint from '../Common/Endpoint';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class AmenityService extends ServiceBase {
  constructor() {
    super(endpoints.admin.amenitiesManagement);
  }

  public getAll = async () => {
    return await this.get<Array<Amenity>>({ id: '' });
  };

  public add = async (model: Amenity) => {
    await this.post({ id: '' }, model);
  };

  public edit = async (model: Amenity) => {
    await this.put({ id: '' }, model);
  };

  public checkCanDelete = async (model: Amenity) => {
    const originalEndpoint = this.endpoint;
    this.endpoint = new Endpoint('amenitiesManagement', '/api/admin/Amenities/CheckCanDelete/{{id}}');
    const found = await this.get<boolean>({ id: model.Id });
    this.endpoint = originalEndpoint;
    return found.data;
  };

  public remove = async (model: Amenity) => {
    await this.delete({ id: model.Id });
  };
}

export default AmenityService;
