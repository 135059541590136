import { ApplicationRoles } from 'src/constants/applicationRoles';
import applicationRoutes from 'src/constants/applicationRoutes';
import Layout from 'src/containers/layout/Layout';
import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';

export const FolioNavItems: RouteItem = {
  text: 'Folio',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [ApplicationRoles.folio, ApplicationRoles.reservations],
  layout: <Layout />,
  path: applicationRoutes.agent.activityFolio,
  async lazy() {
    const component = await import('src-new/features/folio/folio');
    return { Component: component.default };
  }
};
