import { UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ActivityStatusModel } from '../../api/useActivityStatusQuery';

export const useHotelIdsFromStatus = (activityStatus: UseQueryResult<ActivityStatusModel, Error>) => {
  return useMemo(() => {
    if (!activityStatus.data) {
      return [];
    }
    if (activityStatus.data.SelectedHotelId) {
      return [activityStatus.data.SelectedHotelId];
    }
    if (activityStatus.data.HotelRateIds && activityStatus.data.HotelRateIds.length > 0) {
      return activityStatus.data.HotelRateIds;
    }
    if (activityStatus.data.PreferredHotelId) {
      return [activityStatus.data.PreferredHotelId];
    }
    return [];
  }, [activityStatus.data]);
};
