import { useActivityInformationCompanyQuery } from 'src-new/features/activites/informationDisplays/api/useActivityInformationCompanyQuery';
import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { ActivityInformationBox } from 'src-new/features/activites/informationDisplays/components/activityInformationBox';
import { InformationAlertDisplay } from 'src-new/features/activites/informationDisplays/components/informationAlertDisplay';
import { getFullAddressString } from 'src-new/util/formatters/addressFormatters';
import { faBank, faDownload } from '@fortawesome/free-solid-svg-icons';
import { downloadFile } from 'src/utils/helpers/fileHelper';
import { getFormattedUrl } from 'src/utils/helpers/stringHelpers';
import endpoints from 'src/services/Common/endpoints';
import { BaseCard, BaseIcon, BaseSkeletonStack } from '@cksoftware/react-base';

type Props = {
  companyId: number;
};

export const ActivityInformationCompanyDisplay = (props: Props) => {
  const query = useActivityInformationCompanyQuery(props.companyId);
  if (query.isLoading) {
    return <BaseSkeletonStack />;
  }
  if (!query.data) {
    return <></>;
  }
  return (
    <BaseCard
      maxHeight={'90vh'}
      header={
        <Box>
          <Heading mb={'2px'}>{query.data.CompanyName}</Heading>
          <Box fontSize={'sm'}>{getFullAddressString(query.data.Address)}</Box>
          {query.data.GdsCardLastFour && (
            <BaseIcon toolTip={'GDS Card On File'} icon={faBank}>
              <Box> GDS Card On File: {query.data.GdsCardLastFour}</Box>
            </BaseIcon>
          )}
        </Box>
      }>
      <Flex flexDir={'column'} gap={'4px'}>
        <InformationAlertDisplay toDisplay={query.data.CodeRedNotes} status={'error'} header={'Code Red'} />

        <InformationAlertDisplay toDisplay={query.data.AgentNotes} status={'warning'} header={'Agent Notes'} />

        <Box>
          <Box h={'fit-content'} w='100%' css={{ columnCount: [2], columnGap: '8px', gap: '8px' }}>
            <ActivityInformationBox header={'Approval'} htmlText={query.data.ApprovalProcess} />
            <ActivityInformationBox header={'Amenity Notes'} htmlText={query.data.AmenityNotes} />
            <ActivityInformationBox header={'Auth. Booker Policy'} htmlText={query.data.AuthorizedBookerPolicy} />
            <ActivityInformationBox header={'Reporting Requirements'} htmlText={query.data.ReportingRequirementNotes} />
            <ActivityInformationBox header={'Coding Requirements'} htmlText={query.data.CodingRequirements} />

            <ActivityInformationBox header={'Check In Requirements'} htmlText={query.data.CheckInRequirements} />

            <ActivityInformationBox header={'Payment Note'} htmlText={query.data.PaymentNote} />
            <ActivityInformationBox header={'Rooming Policy'} htmlText={query.data.RoomingPolicy} />
            <ActivityInformationBox header={'Service Fees'} htmlText={query.data.ServiceFees} />
            <ActivityInformationBox header={'Travel Policy'} htmlText={query.data.TravelPolicy} />
            <ActivityInformationBox header={'VIP Travel Policy'} htmlText={query.data.VipTravellersPolicy} />

            {(query.data.PaymentMethodsString || query.data.PaymentInformationList?.length > 0) && (
              <ActivityInformationBox header={'Forms Of Payment'}>
                <Text>{query.data.PaymentMethodsString}</Text>
                {query.data.PaymentInformationList.length > 0 && (
                  <>
                    Payment info:
                    {query.data.PaymentInformationList.filter((x) => x.Files.length > 0).map((x) => (
                      <BaseIcon key={`${x.Id}-PaymentInformationList`} toolTip={'Download File'} icon={faDownload}>
                        <Link
                          onClick={async () => {
                            if (x.Files[0].FileHandle) {
                              await downloadFile(
                                getFormattedUrl(endpoints.dataAccess.getFile, { fileHandle: x.Files[0].FileHandle }),
                                x.Files[0].FileHandle
                              );
                            }
                          }}>
                          {x.Name}
                        </Link>
                      </BaseIcon>
                    ))}
                  </>
                )}
              </ActivityInformationBox>
            )}
          </Box>
        </Box>
      </Flex>
    </BaseCard>
  );
};
