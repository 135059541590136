import { UseDisclosureReturn } from '@chakra-ui/react';
import { BaseFormDialog, useAuth, useHookForm } from '@cksoftware/react-base';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import { WorkflowInitialJoinModel, WorkflowJoinStatus } from './Types';
import { useWatch } from 'react-hook-form';
import { TimeOptionsSelect } from 'src-new/features/WorkflowTool/Utils.tsx';

type JoinWorkflowModal = {
  joinStatus: WorkflowJoinStatus;
  onJoin(model: WorkflowInitialJoinModel): void;
  disclosure: UseDisclosureReturn;
};

export const JoinWorkflowModal = (props: JoinWorkflowModal) => {
  const auth = useAuth();

  const hideClose = auth.isInRole([ApplicationRoles.alwaysJoinWorkflow], false);
  const { formMethods, fieldHelper } = useHookForm<WorkflowInitialJoinModel>();

  const isSpectating = useWatch({
    control: formMethods.control,
    name: 'IsSpectating'
  });

  const fields = [
    fieldHelper
      .switchInput('IsInboxManager')
      .withRenderIf(() => auth.isInRole([ApplicationRoles.inboxCoordinator]) && !isSpectating),
    fieldHelper.switchInput('IsSpectating').withRenderIf(() => props.joinStatus == WorkflowJoinStatus.Spectate),
    fieldHelper
      .selectInput('EndDate', { items: TimeOptionsSelect() })
      .withLabel('Shift End')
      .withInputProps({ disableSort: true })
      .withRenderIf(() => !isSpectating)
  ];

  return (
    <BaseFormDialog
      header='Join Workflow Tool'
      isLoading={false}
      hideCloseButton={hideClose}
      disableCloseOnClick={true}
      disclosure={props.disclosure}
      formMethods={formMethods}
      onSubmit={(data) => {
        props.onJoin(data);
      }}
      fields={fields}
    />
  );
};
