import { ITooltipHostStyles, ITooltipProps, TooltipHost } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React, { type JSX } from 'react';
import { useId } from 'src/utils/uiFabricHooks';

interface ITooltip {
  onRenderTooltip: JSX.Element;
  children: React.ReactNode;
}

const TableTooltip: React.FC<ITooltip> = (props: ITooltip) => {
  const tooltipId = useId('tooltip');
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline', cursor: 'pointer' } };
  const tooltipProps: ITooltipProps = {
    onRenderContent: () => {
      return props.onRenderTooltip;
    }
  };
  return (
    <TooltipHost closeDelay={500} tooltipProps={tooltipProps} id={tooltipId} styles={hostStyles}>
      {props.children}
    </TooltipHost>
  );
};

export default observer(TableTooltip);
