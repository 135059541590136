import { getAxios } from 'src/lib/axios';
import { ApiEndpoints } from 'src-new/constants/apiEndpoints';
import { useQuery } from '@tanstack/react-query';
import { PhoneNumberModel } from 'src-new/globalModels/common/phoneNumberModel';

export type ActivityInformationTravellerModel = {
  BranchOffice: string | undefined;
  PhoneNumbers: PhoneNumberModel[];
  Email: string | undefined;
  FirstName: string;
  LastName: string;
  IsVip: boolean;

  PersonalEmail: string | undefined;
  PersonalPhone: string | undefined;

  Title: string | undefined;
  CodeRedNotes: string[];
  AgentNotes: string[];
  HasObtAccess: boolean;
  IsObtSuperUser: boolean;
  IsObtAuthBooker: boolean;
  IsAuthorizedBooker: boolean;
  PersonalPreferences: string | undefined;
  GdsCardLastFour: string | undefined;
  PersonalGdsCardLastFour: string | undefined;
  CompanyName: string;
  PreferredPaymentMethods: string[];
  AuthorizedBookerInformation: string | undefined;
  LoyaltyNumbers: {
    Type: string;
    AccountNumber: string;
  }[];
};

export function useActivityInformationTravellersQuery(travellerIds: number[]) {
  return useQuery<ActivityInformationTravellerModel[]>({
    queryKey: [ApiEndpoints.activitiesNew.information.travellers(travellerIds)],
    queryFn: async () =>
      (await getAxios().get(`${ApiEndpoints.activitiesNew.information.travellers(travellerIds)}`)).data
  });
}
