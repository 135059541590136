import { Flex } from '@chakra-ui/react';
import { useActivityInformationHotelQuery } from 'src-new/features/activites/informationDisplays/api/useActivityInformationHotelQuery';
import { HotelInfoCard } from './HotelInfoCard';
import { BaseSkeletonStack } from '@cksoftware/react-base';

type Props = {
  hotelIds: number[];
};

export const ActivityInformationHotelDisplay = (props: Props) => {
  const query = useActivityInformationHotelQuery([...props.hotelIds].filter((val) => val != undefined));
  if (query.isLoading) {
    return <BaseSkeletonStack />;
  }
  if (!query.data) {
    return <></>;
  }
  return (
    <Flex flexDir={'column'} gap={'8px'} h={'fit-content'} w='100%'>
      {query.data.map((val, key) => (
        <HotelInfoCard val={val} key={key} />
      ))}
    </Flex>
  );
};
