import { Box, VStack } from '@chakra-ui/react';
import { BaseAlert, BaseAlertProps } from '@cksoftware/react-base';

type Props = Pick<BaseAlertProps, 'status'> & {
  header: string;
  toDisplay: string[];
};

export const InformationAlertDisplay = (props: Props) => {
  return (
    <Box>
      {props.toDisplay &&
        props.toDisplay.map((note, index) => {
          return (
            <BaseAlert key={index} status={props.status}>
              <VStack gap={'0px'} alignItems={'flex-start'}>
                <Box fontWeight={'bold'}>{props.header}</Box>
                <Box>{note}</Box>
              </VStack>
            </BaseAlert>
          );
        })}
    </Box>
  );
};
