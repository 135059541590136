import { Box, Span, Text, TextProps } from '@chakra-ui/react';
import { Anchorme } from 'react-anchorme';

import { JSX, useMemo } from 'react';
import { stringIsNullOrEmpty } from 'src/utils/helpers/stringHelpers.ts';

type LabelTextDisplayProps = {
  label: string;
  text?: string;
  textList?: string[];
  render?: () => JSX.Element;
  hideIfTextNull?: boolean;
  lineClamp?: number | undefined;
  textProps?: TextProps;
  fontSize?: string;
};

export const LabelTextDisplay = (props: LabelTextDisplayProps) => {
  const textWithDefault = useMemo(() => {
    if (stringIsNullOrEmpty(props.text)) {
      return '---';
    }
    return props.text;
  }, [props.text]);

  if (props.hideIfTextNull && (!props.text || props.text == '') && !props.textList) {
    return null;
  }

  return (
    <Text
      fontSize={props.fontSize ?? 'sm'}
      lineClamp={props.lineClamp}
      m={0}
      p={0}
      {...props.textProps}
      lineHeight={'1.25rem'}>
      <Span fontWeight={'700'}>{props.label}</Span>: {props.render && props.render()}{' '}
      {props.text && !props.render && <Anchorme>{textWithDefault}</Anchorme>}
      {props.textList &&
        props.textList.map((val, key) => {
          return (
            <Box key={key}>
              <Anchorme>{val}</Anchorme>
            </Box>
          );
        })}
    </Text>
  );
};
