import { initializeIcons } from '@fluentui/react';
import '@fontsource/opendyslexic';
import { Box } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import isSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import dayjsPluginUTC from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ActivityOpenNotificationProvider from 'src-new/features/activites/editActivity/activityOpenNotification/ActivityOpenNotificationProvider';
import { useFrontierAuth } from 'src-new/util/auth/useFrontierAuth';
import { useUserStore } from 'src/stores/useMobxStores';
import { ThemeProvider } from 'styled-components';
import theme from '../theme';
import { ErrorContainer } from './ErrorContainer';
import { useNavWithRouting } from './routes/useNavWithRouting';
import { ChakraRootProvider } from 'src-new/theme/provider';
import { AppToaster } from '@cksoftware/react-base';
import WorkflowProvider from 'src-new/features/WorkflowTool/WorkflowProvider';
import EmailActivitesHubProvider from 'src-new/features/activites/EmailActivitesHubProvider';
import { ActivityInformationContextProvider } from 'src-new/features/activites/informationDisplays/activityInformationContext.tsx';

window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

//This adds support for UTC and local timezone conversions to dayjs
dayjs.extend(dayjsPluginUTC);
dayjs.extend(isSameOrAfterPlugin);
dayjs.extend(isSameOrBeforePlugin);

//This loads icons for the MS Office UI Fabric library throughout the application
initializeIcons();

const tanStackClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: true,
      staleTime: 0,
      gcTime: 0
    }
  }
});

export let portalRef: any;

const App = () => {
  const userStore = useUserStore();
  const { initUser, hasLoaded, isAuthenticated } = useFrontierAuth();
  const portalRef = useRef(null);
  window.global ||= window;

  const AppRouter = createBrowserRouter(useNavWithRouting());

  useEffect(() => {
    async function initAuth() {
      await initUser();
      await userStore.LazyUserModel.current();
    }

    initAuth();
  }, []);

  if (!hasLoaded || userStore.LazyUserModel.pending || !isAuthenticated) {
    return (
      <ChakraRootProvider>
        <QueryClientProvider client={tanStackClient}>
          <ThemeProvider theme={theme}>
            <AppToaster />

            <ErrorContainer>
              <Box ref={portalRef}>
                {hasLoaded && !userStore.LazyUserModel.pending && <RouterProvider router={AppRouter} />}
              </Box>
            </ErrorContainer>
          </ThemeProvider>
        </QueryClientProvider>
      </ChakraRootProvider>
    );
  }

  return (
    <ChakraRootProvider>
      <QueryClientProvider client={tanStackClient}>
        <ThemeProvider theme={theme}>
          <AppToaster />
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          <ActivityOpenNotificationProvider>
            <WorkflowProvider>
              <EmailActivitesHubProvider>
                <ActivityInformationContextProvider>
                  <ErrorContainer>
                    <Box ref={portalRef}>
                      {hasLoaded && !userStore.LazyUserModel.pending && <RouterProvider router={AppRouter} />}
                    </Box>
                  </ErrorContainer>
                </ActivityInformationContextProvider>
              </EmailActivitesHubProvider>
            </WorkflowProvider>
          </ActivityOpenNotificationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </ChakraRootProvider>
  );
};

export default observer(App);
