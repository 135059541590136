export const activityColorSchemes = {
  cancelled: 'red',
  frontierOnline: 'green',
  passive: 'blue',
  active: 'orange',
  manual: 'gray',
  longTerm: 'purple',
  waitingSince: 'yellow',
  obtHotel: 'primary',
  //blue
  databaseHotel: 'secondary',
  personal: 'pink',
  corporate: 'yellow',
  camp: 'brown'
};

export const userColorSchemes = {
  vip: 'yellow',
  obt: 'orange',
  authBooker: 'blue'
};

export const EntityNotesColorScheme = {
  CodeRed: 'secondary.500',
  Frontier: 'primary.500',
  AgentNote: 'green',
  Client: 'yellow',
  Hotel: 'purple',
  Issue: 'orange'
};

export const phoneNumberColorSchemes = {
  business: 'orange',
  fax: 'red',
  personal: 'pink',
  mobile: 'blue'
};

export const emailColorSchemes = {
  primary: 'black',
  business: 'orange',
  fax: 'red',
  personal: 'pink'
};
