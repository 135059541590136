import { makeObservable, observable } from 'mobx';
import CompanyStatus from '../../enums/crm/CompanyStatus';
import ShowActiveModel from '../common/ShowActiveModel';

class CompanyQueryModel extends ShowActiveModel {
  @observable
  public Status: Array<number>;
  @observable
  public Filter: string;
  @observable
  // @ts-expect-error added by automation
  public City: string;

  constructor() {
    super();
    makeObservable(this);
    this.Status = observable.array(new Array<number>());
    this.Status.push(CompanyStatus.Client);
    this.Filter = '';
  }
}

export default CompanyQueryModel;
