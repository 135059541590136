import { ApplicationRoles } from 'src/constants/applicationRoles';
import applicationRoutes from 'src/constants/applicationRoutes';
import Layout from 'src/containers/layout/Layout';
import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';

export const GroupNavItems: RouteItem = {
  text: 'Groups',
  allowedRoles: [ApplicationRoles.groups],
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  layout: <Layout />,
  subItems: [
    {
      text: 'Manage Company Projects',
      path: applicationRoutes.crm.companyProject.list,
      allowedRoles: [ApplicationRoles.groups],
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      async lazy() {
        const component = await import('src/containers/crm/companyProjects/CompanyProjectCrmManagement');
        return { Component: component.default };
      }
    }
  ]
};
