import { Dispatch, ReactNode, useMemo } from 'react';
import { DispatchLike } from 'src-new/stores/types';
import { OmittedDisclosureReturn } from '../Modal/types';
import { Portal } from '@chakra-ui/react';
import { ResizableDrawer, SimpleTab } from '@cksoftware/react-base';

export type FooterTabType = {
  header: string | undefined;
  panel: ReactNode | undefined;
  disabled?: boolean;
};
type Props = {
  disclosure: OmittedDisclosureReturn;
  index: number;
  setIndex: Dispatch<number>;
  tabs: FooterTabType[];
  height: number;
  setHeight: DispatchLike<number>;
};

export const FooterTabsControlled = (props: Props) => {
  const tabs: SimpleTab[] = useMemo(() => {
    return props.tabs.map((v, index) => {
      return {
        key: `footerTabs-${index}-tab`,
        title: v.header,
        disabled: v.disabled
      };
    });
  }, [props.tabs]);

  return (
    <Portal>
      <ResizableDrawer tabs={tabs}>
        {props.tabs.map((v, i) => {
          return <div key={i}>{v.panel}</div>;
        })}
      </ResizableDrawer>
    </Portal>
  );
};
