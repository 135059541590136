import { ActivityInformationHotelModel } from '../api/useActivityInformationHotelQuery';
import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { InformationAlertDisplay } from 'src-new/features/activites/informationDisplays/components/informationAlertDisplay';
import { getFullAddressString } from 'src-new/util/formatters/addressFormatters';
import { LabelTextDisplay } from 'src-new/components/Common/LabelTextDisplay';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { PropsWithChildren, useMemo } from 'react';
import { BaseCard, BaseToolbarButton } from '@cksoftware/react-base';
import { downloadFile } from 'src/utils/helpers/fileHelper.ts';
import { getFormattedUrl } from 'src/utils/helpers/stringHelpers.ts';
import endpoints from 'src/services/Common/endpoints.ts';
import { ContactInfoBar } from 'src-new/components/ContactInfoBar/ContactInfoBar.tsx';

type Props = {
  val: ActivityInformationHotelModel;
} & PropsWithChildren;

export const HotelInfoCard = ({ val, children }: Props) => {
  const convertedContactInfo = useMemo(() => {
    return {
      emails: val.Email ? [{ Address: val.Email, Description: 'Email Address' }] : [],
      phoneNumbers: val.PhoneNumber ? [{ Number: val.PhoneNumber, Description: 'Phone Number' }] : [],
      faxNumbers: val.FaxNumber ? [val.FaxNumber] : [],
      website: val.Website ? { url: val.Website, description: 'Website' } : undefined
    };
  }, [val.Email, val.PhoneNumber, val.FaxNumber, val.Website]);

  return (
    <BaseCard
      variant={'subtle'}
      height={'fit-content'}
      header={
        <Heading>
          <Box>{val.HotelName}</Box>
          <Box fontSize={'xs'}>{getFullAddressString(val.Address)}</Box>
          <Box>
            <ContactInfoBar
              emails={convertedContactInfo.emails}
              phoneNumbers={convertedContactInfo.phoneNumbers}
              faxNumbers={convertedContactInfo.faxNumbers}
              website={convertedContactInfo.website}
            />
          </Box>
        </Heading>
      }>
      <Flex flexDir={'column'} alignItems={'flex-start'}>
        <LabelTextDisplay label={'Website Notes'} text={val.WebsiteNotes} hideIfTextNull={true} lineClamp={3} />
        <LabelTextDisplay label={'Booking Method'} text={val.BookingMethod} hideIfTextNull={true} lineClamp={3} />
        <LabelTextDisplay
          label={'Booking Method Notes'}
          text={val.BookingMethodNotes}
          hideIfTextNull={true}
          lineClamp={3}
        />

        <LabelTextDisplay label={'Payment Notes'} text={val.PaymentNotes} hideIfTextNull={true} lineClamp={3} />
        <LabelTextDisplay label={'Payment Email'} text={val.PaymentEmail} hideIfTextNull={true} lineClamp={3} />
        <LabelTextDisplay label={'CXL Policy'} text={val.CancellationPolicy} hideIfTextNull={true} lineClamp={3} />
        <LabelTextDisplay label={'Chain Code'} text={val.ChainCode} hideIfTextNull={true} lineClamp={3} />
        <LabelTextDisplay label={'GDS Id'} text={val.GdsId} hideIfTextNull={true} lineClamp={3} />
        <LabelTextDisplay label={'Non GDS Id'} text={val.NonGdsId} hideIfTextNull={true} lineClamp={3} />
        <LabelTextDisplay label={'Notes'} text={val.Notes} hideIfTextNull={true} lineClamp={3} />
        {val.CustomPayments && val.CustomPayments.length > 0 && (
          <>
            <LabelTextDisplay label={'Custom Payments'} lineClamp={3} />
            <Box>
              {val.CustomPayments.map((x) => (
                <Box key={`${x.FileHandle}-PaymentInformationList`}>
                  <Text textWrap={'wrap'}>{x.Notes + ' '}</Text>
                  <BaseToolbarButton toolTip={'Download File'} icon={faDownload}>
                    <Link
                      onClick={async () => {
                        if (x.FileHandle) {
                          await downloadFile(
                            getFormattedUrl(endpoints.dataAccess.getFile, { fileHandle: x.FileHandle }),
                            x.FileName
                          );
                        }
                      }}>
                      {x.FileName}
                    </Link>
                  </BaseToolbarButton>
                </Box>
              ))}
            </Box>
          </>
        )}
      </Flex>
      <Box>
        <InformationAlertDisplay toDisplay={val.CodeRedNotes} status={'error'} header={'Code Red'} />
      </Box>
      <Box>
        <InformationAlertDisplay toDisplay={val.AgentNotes} status={'warning'} header={'Agent Notes'} />
      </Box>
      {children}
    </BaseCard>
  );
};
