/* eslint-disable no-console */
import { Link, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { PropsWithChildren } from 'react';
import formatters from 'src/common/formatters';

export function TimeOptionsSelect() {
  let now = dayjs();
  const slots = ((24 - now.hour()) * 60) / 15;
  const arr = Array(slots - 1).fill(0); //subtrack one for additon bellow
  now = now.minute(Math.ceil(now.minute() / 15) * 15);
  return arr.map((_) => {
    const value = now.utc().format(formatters.momentDateTimeUSA);
    const display = now.local().format(`hh:mm A`);
    now = now.add(15, 'minute');
    return {
      Label: display,
      Value: value
    };
  });
}

export function getInitials(name: string) {
  try {
    if (!name) return '';

    // Remove extra spaces and split by one or more whitespace characters.
    const words = name.trim().split(/\s+/);

    // Get the first two letters of the first word.
    const firstWord = words[0];
    const firstTwo = firstWord.slice(0, 2);

    // If there's only one word, just return the first two letters.
    if (words.length === 1) {
      return firstTwo;
    }

    // Otherwise, get the first letter of the last word.
    const lastWord = words[words.length - 1];
    const lastLetter = lastWord.charAt(0);

    return firstTwo + lastLetter;
  } catch (e) {
    console.error(e);
    return 'Err';
  }
}

interface LinkButtonProps extends PropsWithChildren {
  onClick?: () => void;
  fontSize?: string;
}

export function LinkButton({ children, onClick, fontSize }: LinkButtonProps) {
  return (
    <Text as={'u'} fontSize={fontSize ?? 'md'} userSelect={'none'}>
      <Link color='blue' onClick={onClick}>
        {children}
      </Link>
    </Text>
  );
}
