import { ApplicationRoles } from 'src/constants/applicationRoles';
import applicationRoutes from 'src/constants/applicationRoutes';
import Layout from 'src/containers/layout/Layout';
import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';

export const HotelSearchNavItem: RouteItem = {
  text: 'Hotel Search',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [ApplicationRoles.staff],
  layout: <Layout />,
  path: applicationRoutes.agent.obtInquiry,
  async lazy() {
    const component = await import('src-new/features/activites/hotelSearch/HotelSearchContainer');
    return { Component: component.HotelSearchContainer };
  }
};

export const AgentQueryNavItem: RouteItem = {
  text: 'Agent Query',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [ApplicationRoles.staff],
  layout: <Layout />,
  path: applicationRoutes.agent.query,
  async lazy() {
    const component = await import('src/containers/agentquery/AgentQueryContainer');
    return { Component: component.default };
  }
};

export const AgentQuerySubItems: RouteItem = {
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [ApplicationRoles.staff],
  layout: <Layout />,
  subItems: [
    {
      path: `${applicationRoutes.agent.query}/:id`,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.staff],
      async lazy() {
        const component = await import('src/containers/agent/ActivityInquiry/ActivityAgentQueryContainer');
        return { Component: component.default };
      }
    },
    {
      path: `${applicationRoutes.agent.activityInquiry}/:id`,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.staff],
      async lazy() {
        const component = await import('src/containers/agent/ActivityInquiry/ActivityInquiryAgentQueryContainer');
        return { Component: component.default };
      }
    },
    {
      path: `${applicationRoutes.agent.obtInquiry}/:id`,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.staff],
      async lazy() {
        const component = await import('src-new/features/activites/hotelSearch/HotelSearchContainer');
        return { Component: component.HotelSearchContainer };
      }
    }
  ]
};
export const ThirdPartyPaymentDetailsItem: RouteItem = {
  text: 'Third-Party Payment Details Viewer',
  disableDefaultNavItem: true,
  authenticationRequirement: RouteAuthenticationRequirement.Either,
  path: applicationRoutes.account.thirdPartyPaymentDetailsViewer,
  layout: <Layout />,
  async lazy() {
    const component = await import('src-new/features/thirdPartyPaymentDetailsViewer/ThirdPartyPaymentDetailsViewer');
    return { Component: component.ThirdPartyPaymentDetailsViewer };
  }
};
