import { getAxios } from 'src/lib/axios';
import { ApiEndpoints } from 'src-new/constants/apiEndpoints';
import { useQuery } from '@tanstack/react-query';
import { AddressV2 } from 'src-new/globalModels/locations/address';
import { FrontierRoomTypeViewModel } from 'src-new/globalModels/hotels/frontier/frontierRoomTypes';
import { Base64File } from '@cksoftware/react-base';

export type ActivityInformationCompanyModel = {
  ReportingRequirementNotes: string;
  CodingRequirements: string;
  AgentNotes: string[];
  CodeRedNotes: string[];
  RequiredAmenities: string[];
  AmenityNotes: string | undefined;
  ApprovalProcess: string | undefined;
  AuthorizedBookerPolicy: string | undefined;
  CheckInRequirements: string | undefined;
  PaymentNote: string | undefined;
  RoomingPolicy: string | undefined;
  ServiceFees: string | undefined;
  TravelPolicy: string | undefined;
  VipTravellersPolicy: string | undefined;
  GdsCardLastFour: string | undefined;
  CompanyName: string | undefined;
  Address: AddressV2;
  PaymentInformationList: CompanyPaymentInformation[];
  PaymentMethodsString: string;
  UnpreferredNotes: ActivityInformationCompanyUnpreferredHotel[];
  PreferredNotes: ActivityInformationCompanyPreferredHotel[];
};

export type ActivityInformationCompanyHotelPreferences = {
  Preferred: ActivityInformationCompanyPreferredHotel[];
  Unpreferred: ActivityInformationCompanyPreferredHotel[];
};
export type ActivityInformationCompanyUnpreferredHotel = {
  Id: number;
  Name: string;
  CityName: string;
  Note: string;
};
export type ActivityInformationCompanyPreferredHotel = {
  HotelId: number;
  Name: string;
  CityName: string;
  Note: string;
  RoomTypes: FrontierRoomTypeViewModel[];
  RateUrl: string;
  RateSheetHandle: string;
  RateSheetFileName: string;
};

type CompanyPaymentInformation = {
  Id?: number; // Nullable integer Id
  CompanyId: number;
  CreatedBy: string;
  EndDate?: string | null; // Nullable DateTime as string
  Files: Base64File[];
  Name: string;
  Notes: string;
  StartDate?: string | null; // Nullable DateTime as string
};

export function useActivityInformationCompanyQuery(companyId: number) {
  return useQuery<ActivityInformationCompanyModel>({
    queryKey: [ApiEndpoints.activitiesNew.information.company(companyId)],
    queryFn: async () => (await getAxios().get(`${ApiEndpoints.activitiesNew.information.company(companyId)}`)).data
  });
}
