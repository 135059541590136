import { PreTextDisplay } from 'src-new/components/Util/PreTextDisplay';
import { BaseCard } from '@cksoftware/react-base';
import { PropsWithChildren } from 'react';

type Props = {
  header: string;
  renderIfEmpty?: boolean;
  htmlText?: string | undefined;
};

export const ActivityInformationBox = (props: Props & PropsWithChildren) => {
  if ((!props.htmlText || props.htmlText == '') && !props.children) {
    return <></>;
  }
  return (
    <BaseCard variant={'subtle'} size={'sm'} header={props.header} minW={'25%'} display={'inline-block'} w={'100%'}>
      <PreTextDisplay>
        {props.htmlText && <span dangerouslySetInnerHTML={{ __html: props.htmlText }}></span>}
        {props.children}
      </PreTextDisplay>
    </BaseCard>
  );
};
