import { Box, Spinner, useDisclosure } from '@chakra-ui/react';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { cleanLinks } from 'src-new/features/activites/activityNotes/helpers';
import { IEmailActivityOverviewModel } from '../../../../models/activities/IEmailActivityOverviewModel';
import styled from '../../../../utils/styled/styled';
import { EmailIframe } from '../../styledComponents';
import useActivityInfoEmailBodyQuery from 'src-new/features/inbox/api/useActivityInfoEmailBodyQuery';
import { BaseDialog, BaseHoverCard, BaseSubmitButton, BaseToolbarButton } from '@cksoftware/react-base';
import { getActivityOverviewTitle } from 'src/utils/activities/activityModelHelpers';

interface IOverviewTablePreviewEmail {
  model: IEmailActivityOverviewModel;
  isDisabled: boolean;
}

export const PreWrapTextDiv = styled.div`
  white-space: pre-wrap;
`;

const OverviewTablePreviewEmail: React.FC<IOverviewTablePreviewEmail> = (props: IOverviewTablePreviewEmail) => {
  const [doFetch, setDoFetch] = useState(false);
  const emailBodyQuery = useActivityInfoEmailBodyQuery(doFetch, { ClientEmailId: props.model.ClientEmailId });
  const disclosure = useDisclosure();

  return (
    <>
      <BaseHoverCard
        lazyMount={true}
        trigger={
          <BaseToolbarButton
            size={'xs'}
            toolTip='Preview'
            onClick={() => {
              if (disclosure.open) {
                return;
              }
              disclosure.onOpen();
            }}
            icon={faEye}
          />
        }
        onOpenChange={() => {
          if (!props.isDisabled) {
            setDoFetch(true);
          }
        }}>
        <Box w={'500px'}>
          test
          {props.model.ClientEmailId?.toString() && emailBodyQuery.data?.data ? (
            <EmailIframe
              style={{ border: '1px solid black', padding: '8px', backgroundColor: 'white', marginBottom: '12px' }}
              maxHeight={'50vh'}
              srcDoc={cleanLinks(emailBodyQuery.data.data)}
            />
          ) : (
            <Spinner ml={'auto'} mr={'auto'} p={4} />
          )}
        </Box>
      </BaseHoverCard>
      <BaseDialog
        closeOnEsc={true}
        disclosure={disclosure}
        header={<PreWrapTextDiv style={{ fontWeight: 'bold' }}>{getActivityOverviewTitle(props.model)}</PreWrapTextDiv>}
        dragDialog={{ initialH: 650, initialW: 600 }}
        footerRender={
          <BaseSubmitButton
            showConfirm={false}
            onClick={disclosure.onClose}
            buttonText='Close (Esc)'></BaseSubmitButton>
        }>
        {emailBodyQuery.data?.data && (
          <iframe
            srcDoc={cleanLinks(emailBodyQuery.data.data)}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              userSelect: 'text'
            }}
          />
        )}
      </BaseDialog>
    </>
  );
};

export default observer(OverviewTablePreviewEmail);
