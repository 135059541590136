import applicationRoutes from 'src/constants/applicationRoutes';
import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { BaseNavItem } from '@cksoftware/react-base/dist/BaseComponents/Nav/types';
import Layout from 'src/containers/layout/Layout';

export const UserNavItems: BaseNavItem[] = [];

//not rendered in the nav bar (no Text)
export const AccountManagementItems: RouteItem = {
  path: '/',
  layout: <Layout />,
  authenticationRequirement: RouteAuthenticationRequirement.Unauthenticated,
  subItems: [
    {
      authenticationRequirement: RouteAuthenticationRequirement.Unauthenticated,
      path: applicationRoutes.account.resetPassword,
      async lazy() {
        const component = await import('src-new/features/auth/ResetPassword');
        return { Component: component.ResetPasswordPage };
      }
    },
    {
      authenticationRequirement: RouteAuthenticationRequirement.Unauthenticated,
      path: applicationRoutes.account.forgotPassword,
      async lazy() {
        const component = await import('src-new/features/auth/ForgotPassword');
        return { Component: component.ForgotPasswordPage };
      }
    },
    {
      authenticationRequirement: RouteAuthenticationRequirement.Unauthenticated,
      path: applicationRoutes.account.logout,
      async lazy() {
        const component = await import('src-new/features/auth/Logout');
        return { Component: component.Logout };
      }
    }
  ]
};

export const LoginRouteItem: RouteItem = {
  text: 'Login',
  path: applicationRoutes.account.login,
  authenticationRequirement: RouteAuthenticationRequirement.Unauthenticated,
  layout: <Layout />,
  async lazy() {
    const component = await import('src-new/features/auth/Login');
    return { Component: component.LoginPage };
  }
};

export const MyAccountItem: RouteItem = {
  layout: <Layout />,
  path: applicationRoutes.account.myProfile,
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [],

  subItems: [
    {
      text: 'Manage Account',
      path: applicationRoutes.account.myProfile,
      allowedRoles: [],
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      async lazy() {
        const component = await import('src-new/features/auth/MyProfile');
        return { Component: component.MyProfile };
      }
    },
    {
      text: 'Logout',
      path: applicationRoutes.account.logout,
      allowedRoles: [],
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      async lazy() {
        const component = await import('src-new/features/auth/Logout');
        return { Component: component.Logout };
      }
    }
  ]
};
