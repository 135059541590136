import { Stack } from '@chakra-ui/react';
import { createFieldHelper, FormContainer, renderFieldHelperInput, useHookForm } from '@cksoftware/react-base';
import { ApiEndpoints } from 'src-new/constants/apiEndpoints';
import { ActivityInformationCompanyDisplay } from './activityInformationCompanyDisplay';
import { useWatch } from 'react-hook-form';

export const CompanySearch = () => {
  const { formMethods } = useHookForm<{ CompanyId: number | undefined }>({ CompanyId: undefined });
  const companyId = useWatch({
    control: formMethods.control,
    name: 'CompanyId'
  });
  const helper = createFieldHelper<{ CompanyId: number | undefined }>(formMethods);
  const fields = [
    helper
      .selectInputQueryItems('CompanyId', { searchUrl: ApiEndpoints.selectOptions.companies.list })
      .withInputProps({ isClearable: true })
      .isNumeric()
  ];

  return (
    <Stack>
      <FormContainer methods={formMethods} onSubmit={() => {}} hideControls={true}>
        {renderFieldHelperInput('CompanyId', fields)}
      </FormContainer>
      {companyId && <ActivityInformationCompanyDisplay companyId={companyId} />}
    </Stack>
  );
};
