import { ChakraProvider } from '@chakra-ui/react';
import { ColorModeProvider, type ColorModeProviderProps } from './color-mode';
import ChakraTheme, { DyslexicTheme } from './chakraTheme';
import { useProfileStateStore } from 'src-new/features/auth/useProfileStore';

export function ChakraRootProvider(props: ColorModeProviderProps) {
  const store = useProfileStateStore();
  return (
    <ChakraProvider value={store.dyslexicEnabled ? DyslexicTheme : ChakraTheme}>
      <ColorModeProvider {...props} forcedTheme={'light'} />
    </ChakraProvider>
  );
}
