import { CSSProperties, PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  style?: CSSProperties;
};
export const PreTextDisplay = (props: Props) => {
  return (
    <pre
      style={
        props.style
          ? props.style
          : {
              fontFamily: 'inherit',
              whiteSpace: 'pre-wrap',
              lineHeight: '1.5',
              margin: 0,
              padding: 0
            }
      }>
      {props.children}
    </pre>
  );
};
