import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import {
  WorkflowGroupActionModel,
  WorkflowGroupActionType,
  WorkflowGroupCurrentStatusModel,
  WorkflowGroupUserModel
} from './Types';
import { WorkflowContext } from './WorkflowProvider';
import { BaseTooltip } from '@cksoftware/react-base';
import { WorkflowReturnTimeDialog } from 'src-new/features/WorkflowTool/WorkflowReturnTimeDialog.tsx';

interface WorkflowActionButtonProps {
  action: WorkflowGroupActionModel;
  count: number;
  userId: string;
  user: WorkflowGroupUserModel;
  userStatus: WorkflowGroupCurrentStatusModel;
}

export function WorkflowActionButton({ action, count, userId, user, userStatus }: WorkflowActionButtonProps) {
  const [model, invoke] = useContext(WorkflowContext);
  const [status, setStatus] = useState<string | undefined>();
  const [returnDate, setReturnDate] = useState<string | undefined>();
  const disclosure = useDisclosure();

  const isActive =
    userStatus.AssignedAction == action.ActionName ||
    (action.Type == WorkflowGroupActionType.Assignment &&
      userStatus.AssignedActionType == WorkflowGroupActionType.Assigned) ||
    action.Type == WorkflowGroupActionType.Status;
  const showCount = action.Type == WorkflowGroupActionType.Assignment;
  const isEnabled =
    // @ts-expect-error added by automation
    (model.data?.CurrentUser?.UserId == model.data.InboxManagerId &&
      // @ts-expect-error added by automation
      model.data.InboxManagerId &&
      ((userStatus.AssignedActionType == WorkflowGroupActionType.Assigned &&
        action.Type == WorkflowGroupActionType.Assignment) ||
        action.Type == WorkflowGroupActionType.Ready ||
        action.Type == WorkflowGroupActionType.Status ||
        (userStatus.AssignedActionType == WorkflowGroupActionType.Ready &&
          action.Type == WorkflowGroupActionType.Assigned))) ||
    (model.data?.CurrentUser?.UserId == userId &&
      ((userStatus.AssignedActionType == WorkflowGroupActionType.Assigned &&
        action.Type == WorkflowGroupActionType.Assignment) ||
        action.Type == WorkflowGroupActionType.Ready ||
        action.Type == WorkflowGroupActionType.Status ||
        (userStatus.AssignedActionType == WorkflowGroupActionType.Ready &&
          action.Type == WorkflowGroupActionType.Assigned))) ||
    (userStatus.AssignedActionType == WorkflowGroupActionType.Status &&
      action.Type != WorkflowGroupActionType.Assignment);

  return (
    <>
      <BaseTooltip content={`${action.ActionName}`}>
        <Box
          cursor={isEnabled ? 'pointer' : 'not-allowed'}
          userSelect={'none'}
          m={'0'}
          shadow={'black 1px 1px'}
          bg={isActive ? action.ColorHexCode : 'grey'}
          height={'25px'}
          width={'25px'}
          position={'relative'}
          p={'0'}
          borderRadius={'0'}
          outline={'none'}
          display={'flex'}
          flexDir={'column'}
          _focus={{
            boxShadow: 'inset 0px 0px 5px white',
            outline: 'none'
          }}
          color={'Menu'}
          onClick={() => {
            if (!isEnabled) {
              return;
            }

            if (action.RequiresStatusMessage) {
              disclosure.onOpen();
            } else {
              // @ts-expect-error added by automation
              invoke('ActionAssigned', user, action.SortOrder, action.Id, userId, model.data.WorkflowGroupId, '', null);
            }
          }}>
          <Text pl={'0.1em'} textAlign={'center'} alignSelf={'center'} fontSize={'0.7em'} as={'b'}>
            {action.ActionAbbreviation}
          </Text>
          <Text pos={'absolute'} bottom={'-1px'} left={'10px'} fontSize={'0.7em'} pr={'0.2em'} color={'white'}>
            {showCount && typeof count == 'number' && Math.min(count, 999)}
          </Text>
        </Box>
      </BaseTooltip>
      <WorkflowReturnTimeDialog
        disclosure={disclosure}
        onSubmit={async (message, time) => {
          setStatus(message);
          setReturnDate(time);
          await invoke(
            'ActionAssigned',
            user,
            action.SortOrder,
            action.Id,
            userId,
            // @ts-expect-error added by automation
            model.data.WorkflowGroupId,
            status ?? '',
            returnDate ?? ''
          );
          setStatus('');
          setReturnDate(undefined);
          disclosure.onClose();
        }}
      />
    </>
  );
}
