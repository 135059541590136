import { useQuery } from '@tanstack/react-query';
import { ApiEndpoints } from 'src-new/constants/apiEndpoints';
import ActivityStatus from 'src/enums/activities/ActivityStatus';
import { getAxios } from 'src/lib/axios';
import { QueryKeys } from 'src-new/constants/queryKeys';
import { SelectOption } from '@cksoftware/react-base';
import { HotelBookingMethod } from 'src-new/globalModels/common/hotelBookingMethod';
import { HotelBookingType } from 'src-new/globalModels/common/hotelBookingType';

export type TravellerNameIdModel = {
  Id: number;
  FirstName: string;
  LastName: string;
  FullName: string;
};

export type ActivityStatusModel = {
  Id: number;
  TabTitle: string;
  ActivityHeader: string;
  CancellationHeader: string | undefined;
  FirstEmailSubject: string | undefined;
  Status: ActivityStatus;
  OriginalFlsqs: string[];
  IsObt: boolean;
  IsLongTerm: boolean;
  IsCancelled: boolean;
  HasSelectedHotelRates: boolean;
  HasSentItinerary: boolean;
  HasItinerary: boolean;
  HasDetailedPayment?: boolean;
  HasBookedHotel: boolean;
  DisableEdit: boolean;
  SelectedRateId?: number;
  HotelRateIds: number[];
  Travellers: TravellerNameIdModel[];
  TravellerSelectOptions: SelectOption[];
  CompanyId: number | undefined;
  CompanyName: string | undefined;
  SelectedHotelId?: number;
  Flsq?: number;
  CheckInDate: string | undefined;
  CheckOutDate: string | undefined;
  AuthorizedBookerId: number | undefined;
  ApprovedBookerId: number | undefined;
  HasFormOfPayment?: boolean;
  WaitingSince?: string;
  PreferredHotelId?: number;
  AssignedToUserId?: string;
  HotelBookingMethod: HotelBookingMethod;
  HotelBookingType: HotelBookingType;
  PaymentEmailSent: boolean;
  PreferredCity?: string;
  HasPersonalTravelCard?: boolean;
};

export function useActivityStatusQuery(activityId: number | undefined) {
  return useQuery<ActivityStatusModel>({
    queryKey: [
      QueryKeys.editActivity(activityId ?? -1),
      ApiEndpoints.activities.activityStatus(activityId ?? -1),
      activityId
    ],
    queryFn: async () => (await getAxios().get(`${ApiEndpoints.activities.activityStatus(activityId ?? -1)}`)).data,
    enabled: !!activityId && !isNaN(activityId),
    retry: false
  });
}
