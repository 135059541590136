import { ApplicationRoles } from 'src/constants/applicationRoles';
import applicationRoutes from 'src/constants/applicationRoutes';
import Layout from 'src/containers/layout/Layout';
import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';

export const MirNavItems: RouteItem = {
  text: 'MIR',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [ApplicationRoles.mirAdmin],
  layout: <Layout />,

  subItems: [
    {
      text: 'MIR File Report',
      path: applicationRoutes.mir.mirFileReport,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.mirAdmin],
      async lazy() {
        const component = await import('src-new/features/mirAdmin/mirFileReport/mirFileReport');
        return { Component: component.MirFileReport };
      }
    },
    {
      text: 'Activity MIR Report',
      path: applicationRoutes.mir.mirActivityReport,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.mirAdmin],
      async lazy() {
        const component = await import('src-new/features/mirAdmin/mirActivityMissingReport/mirActivityMissingFile');
        return { Component: component.MirActivityMissingReport };
      }
    },
    {
      text: 'MIR Agent Error Report',
      path: applicationRoutes.mir.mirAgentError,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.mirAdmin],
      async lazy() {
        const component = await import('src-new/features/mirAdmin/mirAgentErrorReport/mirErrorReport');
        return { Component: component.MirAgentErrorReport };
      }
    }
  ]
};
