import { Box, Image, Link, UseDisclosureReturn } from '@chakra-ui/react';
import { useFrontierAuth } from 'src-new/util/auth/useFrontierAuth';
import { BaseButton, BaseNav, RouteAuthenticationRequirement, useNavItemStore } from '@cksoftware/react-base';
import { useMemo } from 'react';
import ApplicationRoutes from 'src/constants/applicationRoutes';
import logo from '../../assets/frontier-logo-new.png';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons/faUserGroup';
import { AnnouncementBox } from '../announcments/components/AnnouncementBox';
import { useUtilityStore } from 'src/stores/useMobxStores';
import GeneralSearchDialog from 'src/containers/search/GeneralSearchDialog';
import { ApplicationRoles } from 'src/constants/applicationRoles.ts';
import ModalWidth from 'src/enums/common/ModalWidth.ts';
import { BaseNavItem } from '@cksoftware/react-base/dist/BaseComponents/Nav/types';

export const NavBar = (props: { joinWorkflowDisclosure: UseDisclosureReturn; isInWorkflow: boolean }) => {
  // const betaState = useBetaStateStore();
  const utilStore = useUtilityStore();

  const auth = useFrontierAuth();
  const routeNav = useNavItemStore((state) => {
    return { items: state.getDefault(), get: state.get };
  });

  const profileItems: BaseNav.NavItem = useMemo(() => {
    const subItems: BaseNav.NavItem[] = [
      routeNav.get(ApplicationRoutes.account.myProfile),
      routeNav.get(ApplicationRoutes.account.logout)
    ];
    return {
      text: 'Profile',
      subItems: subItems,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: []
    };
  }, []);

  const navItems: BaseNavItem[] = useMemo(() => {
    const toReturn = [];
    toReturn.push({
      text: 'General Search',
      allowedRoles: [],
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      onClick: () => {
        utilStore.modalState.open(
          <GeneralSearchDialog allowEditUser={auth.isInRole([ApplicationRoles.crm])} />,
          ModalWidth.Fullscreen
        );
      }
    });
    routeNav.items.forEach((item) => {
      toReturn.push(item);
    });
    return toReturn as BaseNavItem[];
  }, [routeNav.items]);

  return (
    <BaseNav.Header
      profileItems={profileItems}
      afterNavChildren={
        auth.isAuthenticated && (
          <Box h={'100%'} w={'400px'}>
            <AnnouncementBox />
          </Box>
        )
      }
      logo={
        <Link href='/'>
          <Image height={['40px']} src={logo || undefined} alt='Frontier Lodging Solutions' />
        </Link>
      }
      navItems={navItems}
      avatarName={`${auth.user?.Name} test`}>
      {auth?.user?.EnableWorkflowSystem && !props.isInWorkflow && (
        <Box fontSize={'small'} justifySelf={'flex-end'}>
          <BaseButton onClick={props.joinWorkflowDisclosure.onOpen} icon={faUserGroup}>
            Join Workflow
          </BaseButton>
        </Box>
      )}
      {/*<Flex gap={'8px'} alignItems={'center'}>*/}
      {/*  <BaseFields.SwitchInput*/}
      {/*    size={'sm'}*/}
      {/*    checked={betaState.betaEnabled}*/}
      {/*    onChangeControlled={(val) => {*/}
      {/*      betaState.setEnableBeta(val ?? false);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  <Text m={0} p={0} fontSize={'small'}>*/}
      {/*    Beta*/}
      {/*  </Text>*/}
      {/*</Flex>*/}
    </BaseNav.Header>
  );
};
