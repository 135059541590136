export const isNullOrUndefined = (test: any): test is undefined | null => {
  return isUndefined(test) || isNull(test);
};
export const isNullOrUndefinedOrEmptySpace = (test: any): test is undefined | null => {
  return isUndefined(test) || isNull(test) || test.trim() === '';
};

export const isNullOrUndefinedOrNan = (test: any): test is undefined | null => {
  return isUndefined(test) || isNull(test) || isNaN(test);
};

const isNull = (test: any): test is null => {
  return test === null;
};

const isUndefined = (test: any): test is undefined => {
  return typeof test === 'undefined';
};

export function arrayIsNullOrEmpty(array: Array<any> | undefined): array is undefined | [] {
  const toTest = emptyArrayIfNull(array);
  return toTest.length === 0;
}

export function emptyArrayIfNull<T>(array: Array<T> | undefined) {
  if (array) {
    return array;
  }
  return [];
}
