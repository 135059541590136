import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import FaIconWithToolTip from '../../components/common/FaIconWithToolTip';
import { PreWrapTextDiv } from '../../components/common/PreWrapTextDiv';
import { ApplicationRoles } from '../../constants/applicationRoles';
import applicationRoutes from '../../constants/applicationRoutes';
import { CompanyModel } from '../../models/companies/CompanyModel';
import AgentQuerySearchCompanyResultModel from '../../models/search/AgentQuerySearchCompanyResultModel';
import { useUserStore, useUtilityStore } from '../../stores/useMobxStores';
import { formatPhoneNumber } from '../../utils/helpers/crmHelpers';
import { onStyledDetailListRow } from '../../utils/styled/styledDetailListRow';
import { companyAgentColumns, companyColumns } from './searchcolumns';
import { BoldRedFontDiv } from '../agentquery/styledComponents';
import { BaseCard } from '@cksoftware/react-base';

interface ICompanyResultTable {
  companies: Array<AgentQuerySearchCompanyResultModel>;
}

const CompanyResultTable: React.FC<ICompanyResultTable> = (props: ICompanyResultTable) => {
  const userStore = useUserStore();
  const utilStore = useUtilityStore();
  const user = userStore.LazyUserModel.current();
  const navigate = useNavigate();

  const onNavigate = (id: number) => {
    navigate(applicationRoutes.crm.company.edit + `/${id}`);
    utilStore.modalState.close();
  };

  const renderCompany = (item: CompanyModel, index: number, column: IColumn) => {
    switch (column.key) {
      case 'City':
        return item.Active ? (
          <PreWrapTextDiv>{item.Address.City}</PreWrapTextDiv>
        ) : (
          <BoldRedFontDiv>{item.Address.City}</BoldRedFontDiv>
        );
      case 'Name':
        return item.Active ? (
          <PreWrapTextDiv>{item.Name}</PreWrapTextDiv>
        ) : (
          <BoldRedFontDiv>{item.Name} - INACTIVE</BoldRedFontDiv>
        );
      case 'PhoneNumber':
      case 'FaxNumber':
        return item.Active ? (
          formatPhoneNumber(item[column.key])
        ) : (
          <BoldRedFontDiv>{formatPhoneNumber(item[column.key])}</BoldRedFontDiv>
        );
      case 'Actions':
        return (
          <FaIconWithToolTip
            tooltip={'Go to Company Page'}
            iconName={'fa-pen'}
            clickable
            onClick={() => onNavigate(item.Id)}
          />
        );
      default:
        // @ts-expect-error added by automation
        return <PreWrapTextDiv>{item[column.key]}</PreWrapTextDiv>;
    }
  };

  return (
    <div>
      {props.companies?.length > 0 && (
        <BaseCard header={'Companies'}>
          <DetailsList
            items={props.companies.map((x) => x.Company)}
            columns={user?.IsInRole([ApplicationRoles.crm]) ? companyColumns : companyAgentColumns}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            onRenderRow={onStyledDetailListRow}
            // @ts-expect-error added by automation
            onRenderItemColumn={renderCompany}
          />
        </BaseCard>
      )}
    </div>
  );
};
export default observer(CompanyResultTable);
