import { Box, HStack, Text } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { UserStatus } from 'src/enums/common/UserStatus';
import { WorkflowGroupActionType, WorkflowGroupUserModel } from './Types';
import { WorkflowCurrentUser } from './WorkflowCurrentUser';
import { WorkflowContext } from './WorkflowProvider';
import { WorkflowUser } from './WorkflowUser';
import { useLayoutStore } from 'src-new/stores/layoutStore';

export enum WorkListMode {
  Max = 0,
  Min = 1,
  Hide = 2
}

function sortUserArray(users: WorkflowGroupUserModel[]) {
  if (!users) {
    return [];
  }
  const toReturn = new Array<WorkflowGroupUserModel>();
  const copy = users.slice();
  copy
    .filter(
      (value) =>
        value.CurrentStatus.AssignedActionType === WorkflowGroupActionType.Ready &&
        value.CurrentStatus.Status != UserStatus.Away
    )
    .sort((a, b) => a.Name.localeCompare(b.Name))
    .forEach((value) => {
      toReturn.push(value);
    });

  copy
    .filter(
      (value) =>
        value.CurrentStatus.AssignedActionType !== WorkflowGroupActionType.Ready &&
        value.CurrentStatus.Status != UserStatus.Away
    )
    .sort((a, b) => a.Name.localeCompare(b.Name))
    .forEach((value) => {
      toReturn.push(value);
    });
  copy
    .filter((value) => value.CurrentStatus.Status == UserStatus.Away)
    .sort((a, b) => a.Name.localeCompare(b.Name))
    .forEach((value) => {
      toReturn.push(value);
    });
  return toReturn;
}

export default function WorkflowList({ mode }: { mode: WorkListMode }) {
  const [userQuery, invoke] = useContext(WorkflowContext);
  const layoutStore = useLayoutStore();
  const [once, setOnce] = useState(true);
  useEffect(() => {
    if (invoke) {
      if (once) {
        setOnce(false);
        invoke('Join');
      }
    }
  }, [invoke]);

  useEffect(() => {
    layoutStore.triggerResizeEvent();
  }, [mode]);

  let height = '109px';
  switch (mode) {
    case WorkListMode.Max:
      height = '100%';
      break;
    case WorkListMode.Min:
      height = '90px';
      break;
    case WorkListMode.Hide:
      height = '0';
      break;
  }
  if (mode == WorkListMode.Hide || userQuery == null) {
    return <></>;
  }

  if (userQuery?.isPending || !userQuery?.data) {
    return <Text>Loading....</Text>;
  }

  return (
    <HStack gap={0} height={height} overflow={'hidden'}>
      {userQuery.data?.CurrentUser && (
        <Box alignSelf={'flex-start'} height={'100%'}>
          <WorkflowCurrentUser
            user={userQuery.data?.CurrentUser}
            inboxManagerId={userQuery.data.InboxManagerId}
            actions={userQuery.data.Actions}
            inboxManager={userQuery.data.InboxManager}
          />
        </Box>
      )}
      {mode == WorkListMode.Min
        ? sortUserArray(userQuery.data.Users)
            .slice(0, 18)
            .map((workflowUser) => {
              return (
                <WorkflowUser
                  key={`${workflowUser.UserId}-2`}
                  user={workflowUser}
                  inboxManagerId={userQuery.data.InboxManagerId}
                  actions={userQuery.data.Actions}
                />
              );
            })
        : sortUserArray(userQuery.data?.Users ?? []).map((workflowUser) => {
            return (
              <WorkflowUser
                key={`${workflowUser.UserId}-2`}
                user={workflowUser}
                inboxManagerId={userQuery.data!.InboxManagerId}
                actions={userQuery.data!.Actions}
              />
            );
          })}
    </HStack>
  );
}
