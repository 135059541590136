import { defineSlotRecipe } from '@chakra-ui/react';
import { menuAnatomy } from '@chakra-ui/react/anatomy';

export const menuSlotRecipe = defineSlotRecipe({
  className: 'chakra-menu',
  slots: menuAnatomy.keys(),
  base: {
    positioner: {
      overflow: 'visible'
    }
  }
});
