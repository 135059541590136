import { getAxios } from 'src/lib/axios';
import { ApiEndpoints } from 'src-new/constants/apiEndpoints';
import { useQuery } from '@tanstack/react-query';
import { AddressV2 } from 'src-new/globalModels/locations/address';

export type ActivityInformationHotelModel = {
  AgentNotes: string[];
  CodeRedNotes: string[];
  Address: AddressV2;
  BookingMethod: string | undefined;
  BookingMethodNotes: string | undefined;
  CancellationPolicy: string | undefined;
  ChainCode: string | undefined;
  Email: string | undefined;
  PaymentEmail: string | undefined;
  FaxNumber: string | undefined;
  GdsId: string | undefined;
  NonGdsId: string | undefined;
  Notes: string | undefined;
  PaymentNotes: string | undefined;
  PhoneNumber: string | undefined;
  Website: string | undefined;
  WebsiteNotes: string | undefined;
  HotelName: string;
  CustomPayments: HotelCustomPayment[];
  FrontierPreferred: boolean;
};

type HotelCustomPayment = {
  Notes: string;
  FileName: string;
  FileHandle: string;
};

export function useActivityInformationHotelQuery(hotelIds: number[]) {
  return useQuery<ActivityInformationHotelModel[]>({
    queryKey: [ApiEndpoints.activitiesNew.information.hotels(hotelIds)],
    queryFn: async () => (await getAxios().get(`${ApiEndpoints.activitiesNew.information.hotels(hotelIds)}`)).data,
    enabled: hotelIds != undefined && hotelIds?.length != 0
  });
}

export type ActivityInformationHotelContractModel = {
  ChargeFee: boolean;
  DynamicRateDiscount: number | undefined;
  DynamicRateCommission: number | undefined;
  StaticRateCommission: number | undefined;
};
