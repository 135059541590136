import { ContextualMenu, IContextualMenuItem } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import styled from '../../utils/styled/styled';
import FaIcon from '../common/FaIcon';
import FaIconWithToolTip from '../common/FaIconWithToolTip';

const StyledDiv = styled.div`
  margin-left: 7px;
`;

interface IHubContextMenu {
  contextItems: Array<IContextualMenuItem>;
  toolTip?: string;
  iconName?: string;
}

const HubContextMenu: React.FC<IHubContextMenu> = (props) => {
  const [showContextualMenu, setShowContextualMenu] = useState(false);
  const showContextMenu = () => {
    setShowContextualMenu(true);
  };
  const hideContextMenu = () => {
    setShowContextualMenu(false);
  };

  const iconName = props.iconName || `fa-ellipsis-v`;
  const clickableRef = useRef(null);
  return (
    <>
      <StyledDiv ref={clickableRef} onClick={showContextMenu}>
        {props.toolTip ? (
          <FaIconWithToolTip tooltip={props.toolTip} clickable altBaseClassName={'far'} iconName={iconName} />
        ) : (
          <FaIcon clickable iconName={iconName} />
        )}
      </StyledDiv>
      <ContextualMenu
        shouldFocusOnContainer={true}
        items={props.contextItems}
        hidden={!showContextualMenu}
        target={clickableRef as any}
        onItemClick={showContextMenu}
        onDismiss={hideContextMenu}
      />
    </>
  );
};
export default observer(HubContextMenu);
