import { Navigate, RouteObject } from 'react-router-dom';

import Home from 'src/containers/layout/Home';
import Layout from 'src/containers/layout/Layout';
import { RouteError } from './RouteError';
import { AccountManagementItems, LoginRouteItem, MyAccountItem } from './SectionRoutes/AccountRoutes';
import { AdminNavItems } from './SectionRoutes/AdminRoutes';
import { AgentEmailNavItems } from './SectionRoutes/AgentEmailRoutes';
import {
  AgentQueryNavItem,
  AgentQuerySubItems,
  HotelSearchNavItem,
  ThirdPartyPaymentDetailsItem
} from './SectionRoutes/AgentQueryRoutes';
import { CrmNavItems } from './SectionRoutes/CrmRoutes';
import { FolioNavItems } from './SectionRoutes/FolioRoutes';
import { GroupNavItems } from './SectionRoutes/GroupRoutes';
import { MirNavItems } from './SectionRoutes/MirRoutes';
import { ReportingNavItems } from './SectionRoutes/ReportingRoutes';
import { BaseNav, buildNavItemFromRoute, buildRoute, useNavItemStore } from '@cksoftware/react-base';

export const AuthenticatedRouteItems = [
  HotelSearchNavItem,
  AgentQuerySubItems,
  AgentQueryNavItem,
  AgentEmailNavItems,
  ReportingNavItems,
  FolioNavItems,
  CrmNavItems,
  GroupNavItems,
  MirNavItems,
  AdminNavItems,
  MyAccountItem,
  AccountManagementItems
];
const UnauthenticatedRouteItems = [LoginRouteItem, AccountManagementItems, ThirdPartyPaymentDetailsItem];

export function useNavWithRouting() {
  const routes = new Array<RouteObject>();
  const nav: BaseNav.NavItem[] = [];
  const setNavItemStore = useNavItemStore((state) => state.set);

  routes.push({
    path: '/',
    element: <Layout />,
    errorElement: <RouteError />,
    children: [{ path: '/', element: <Home />, errorElement: <RouteError /> }]
  });

  AuthenticatedRouteItems.forEach((value) => {
    routes.push(buildRoute(value));
    const navItem = buildNavItemFromRoute(value);
    if (navItem) {
      nav.push(navItem);
    }
  });

  UnauthenticatedRouteItems.forEach((value) => {
    routes.push(buildRoute(value));
    const navItem = buildNavItemFromRoute(value);
    if (navItem) {
      nav.push(navItem);
    }
  });

  routes.push({
    path: '/spa/*',
    element: <Navigate to={window.location.pathname.toString().replace('/spa', '')} replace />
  });

  setNavItemStore(nav);
  return routes;
}
