import { observer } from 'mobx-react-lite';
import React from 'react';
import { IEmailActivityOverviewModel } from '../../../../models/activities/IEmailActivityOverviewModel';
import { getActivityOverviewTitle } from '../../../../utils/activities/activityModelHelpers';
import styled from '../../../../utils/styled/styled';
import OverviewTablePreviewEmail from './OverviewTablePreviewEmail';
import { Box, Flex } from '@chakra-ui/react';
import { HotelBookingType } from 'src-new/globalModels/common/hotelBookingType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons';

interface IActivityEmailDetailsListSubject {
  model: IEmailActivityOverviewModel;
  massMerge?: boolean;
}

export const PreWrapTextDiv = styled.div`
  white-space: pre-wrap;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const stylesMarginRight = {
  marginRight: '5px',
  marginLeft: '5px'
};

const stylesNoMargin = {
  marginRight: '0px',
  marginLeft: '0px'
};

const stylesMarginLeft = {
  marginRight: '0px',
  marginLeft: '5px'
};

const ActivityEmailDetailsListSubject: React.FC<IActivityEmailDetailsListSubject> = (
  props: IActivityEmailDetailsListSubject
) => {
  return (
    <Flex justifyContent={'space-between'}>
      <FlexContainer>
        <Box width={'40px'} alignSelf={'flex-start'}>
          {!props.massMerge && (
            <OverviewTablePreviewEmail model={props.model} isDisabled={!props.model.ClientEmailId} />
          )}
        </Box>

        <Flex gap={'4px'}>
          <Box>
            {props.model.HotelBookingType == HotelBookingType.Personal && <FontAwesomeIcon icon={faUmbrellaBeach} />}
          </Box>

          <PreWrapTextDiv>{getActivityOverviewTitle(props.model)}</PreWrapTextDiv>
        </Flex>
      </FlexContainer>
      <div>
        {!!props.model.FileName && <i className={'fa fa-envelope-o'} style={stylesNoMargin}></i>}
        {!!props.model.attachmentcount && <i className={'fa fa-paperclip'} style={stylesMarginRight}></i>}
        {props.model.attachmentcount === 0 && <i className={'fa fa-fw'} style={stylesMarginLeft}></i>}
      </div>
    </Flex>
  );
};

export default observer(ActivityEmailDetailsListSubject);
