import { arrayIsNullOrEmpty, BaseToolbar, BaseToolbarButton, useStandardToast } from '@cksoftware/react-base';
import { EmailAddressModel } from 'src-new/globalModels/common/emailAddressModel.ts';
import { PhoneNumberModel } from 'src-new/globalModels/common/phoneNumberModel.ts';
import { Anchorme } from 'react-anchorme';
import { formatPhoneNumber } from 'src/utils/helpers/crmHelpers.ts';
import { faAt, faEnvelope, faFax, faGlobe, faMobile, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@chakra-ui/react';
import addTextMagicDomain from 'src-new/util/formatters/phoneToEmailFormatter.ts';
import { PhoneNumberType } from 'src/enums/crm/PhoneNumberType.ts';
import { EmailAddressType } from 'src/enums/crm/EmailAddressType.ts';

type Props = {
  emails: Partial<EmailAddressModel>[];
  phoneNumbers: Partial<PhoneNumberModel>[];
  faxNumbers: string[];
  website?: {
    url: string;
    description: string;
  };
};

export const ContactInfoBar = (props: Props) => {
  const toast = useStandardToast();

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    toast.successToast('Copied to clipboard', 'Contents have been copied to your clipboard.');
  };

  if (
    arrayIsNullOrEmpty(props.emails) &&
    arrayIsNullOrEmpty(props.phoneNumbers) &&
    arrayIsNullOrEmpty(props.faxNumbers) &&
    !props.website
  ) {
    return null;
  }

  return (
    <>
      <BaseToolbar>
        {props.emails
          .filter((val) => val.Address)
          .map((email, index) => {
            return (
              <BaseToolbarButton
                toolTip={email.Description ?? 'Email Address'}
                icon={email.Type == EmailAddressType.Personal ? faAt : faEnvelope}
                key={index}
                onClick={() => {
                  copyToClipboard(email.Address ?? '');
                }}>
                {email.Address ?? ''}
              </BaseToolbarButton>
            );
          })}
        {props.phoneNumbers
          .filter((val) => val.Number)
          .map((phoneNumber, index) => {
            return (
              <BaseToolbarButton
                toolTip={
                  phoneNumber.Description ??
                  `${PhoneNumberType[phoneNumber.Type ?? PhoneNumberType.Business]} Phone Number`
                }
                icon={phoneNumber.Type == PhoneNumberType.Mobile ? faMobile : faPhone}
                key={index}
                onClick={() => copyToClipboard(phoneNumber.Number!)}>
                {formatPhoneNumber(phoneNumber.Number)!}
              </BaseToolbarButton>
            );
          })}
        {props.phoneNumbers
          .filter((val) => val.Number)
          .map((phoneNumber, index) => {
            return (
              <BaseToolbarButton
                toolTip={
                  phoneNumber.Description ??
                  `${PhoneNumberType[phoneNumber.Type ?? PhoneNumberType.Business]} Phone Number Text Magic`
                }
                icon={phoneNumber.Type == PhoneNumberType.Mobile ? faMobile : faPhone}
                key={index}
                onClick={() => copyToClipboard(addTextMagicDomain(phoneNumber.Number!))}>
                {formatPhoneNumber(phoneNumber.Number)!} (TM)
              </BaseToolbarButton>
            );
          })}
        {props.faxNumbers.map((faxNumber, index) => {
          return (
            <BaseToolbarButton
              toolTip={'Fax Number'}
              icon={faFax}
              key={index}
              onClick={() => copyToClipboard(`${faxNumber}@fax.telus.net`)}>
              {formatPhoneNumber(faxNumber)}
            </BaseToolbarButton>
          );
        })}
        {props.website && (
          <BaseToolbarButton toolTip={'Website'} icon={faGlobe}>
            <Anchorme target='_blank'>{props.website.url}</Anchorme>
          </BaseToolbarButton>
        )}
      </BaseToolbar>
      <Box w={'100%'} lineHeight={'10px'} m={'0'} p={'0 0 4px 0'} fontSize={'xs'} fontStyle={'italic'}>
        New: click to copy to clipboard. Phone numbers with (TM) = textmagic
      </Box>
    </>
  );
};
