import { ActivityInformationDisplayProps } from 'src-new/features/activites/informationDisplays/types';
import { useMemo } from 'react';
import { ActivityStatusModel } from '../../api/useActivityStatusQuery';
import { HotelBookingType } from 'src-new/globalModels/common/hotelBookingType';
import { SimpleTab } from '@cksoftware/react-base';

export type UseActivityDataDisplayRenderSwitchesReturn = {
  renderPolicies: boolean;
  renderTravellers: boolean;
  renderHotel: boolean;
  renderDrawer: boolean;
};

export const useActivityInformationRenderSwitchesWithStatus = (
  props: ActivityStatusModel | undefined,
  hotelIds: number[]
) => {
  return useActivityInformationRenderSwitches({
    companyId: props?.CompanyId,
    hotelIds,
    travellerIds: props?.Travellers.flatMap((x) => x.Id),
    approvedBookerId: props?.ApprovedBookerId,
    authorizedBookerId: props?.AuthorizedBookerId,
    hotelBookingType: props?.HotelBookingType
  });
};

export const useActivityInformationRenderSwitches = (props: ActivityInformationDisplayProps) => {
  return useMemo(() => {
    const renderPolicies = props.companyId != undefined && props.hotelBookingType != HotelBookingType.Personal;
    const renderTravellers =
      (props.travellerIds != undefined && props.travellerIds.length > 0) ||
      !!props.authorizedBookerId ||
      !!props.approvedBookerId;

    const renderHotel = props.hotelIds != undefined && props.hotelIds.length > 0;
    const renderDrawer = renderPolicies || renderTravellers || renderHotel;
    return {
      renderPolicies,
      renderTravellers,
      renderHotel,
      renderDrawer
    } as UseActivityDataDisplayRenderSwitchesReturn;
  }, [props]);
};

export const useActivityInformationHeaders = (
  renderSwitches: UseActivityDataDisplayRenderSwitchesReturn
): SimpleTab[] => {
  return [
    {
      key: 'company',
      title: 'Company',
      disabled: !renderSwitches.renderPolicies
    },
    {
      key: 'travellers',
      title: 'Travellers',
      disabled: !renderSwitches.renderTravellers
    },
    {
      key: 'hotels',
      title: 'Hotels',
      disabled: !renderSwitches.renderHotel
    }
  ];
};
