import Result from '../../models/common/Result';
import AgentQuerySearchBranchOfficeResultModel from '../../models/search/AgentQuerySearchBranchOfficeResultModel';
import AgentQuerySearchCompanyResultModel from '../../models/search/AgentQuerySearchCompanyResultModel';
import AgentQuerySearchModel from '../../models/search/AgentQuerySearchModel';
import AgentQuerySearchResultModel from '../../models/search/AgentQuerySearchResultModel';
import AgentQuerySearchTravellerResultModel from '../../models/search/AgentQuerySearchTravellerResultModel';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class SearchService extends ServiceBase {
  constructor() {
    super(endpoints.search.search);
  }

  public Search = async (model: AgentQuerySearchModel) => {
    const response = await this.post<Result<AgentQuerySearchResultModel>>(null, model);
    const toReturn = new AgentQuerySearchResultModel();
    response.data.Value.BranchOffices.forEach((element) => {
      const branchOffice = new AgentQuerySearchBranchOfficeResultModel();
      branchOffice.SetData(element);
      toReturn.BranchOffices.push(branchOffice);
    });
    response.data.Value.Companies.forEach((element) => {
      const branchOffice = new AgentQuerySearchCompanyResultModel();
      branchOffice.SetData(element);
      toReturn.Companies.push(branchOffice);
    });
    response.data.Value.Travellers.forEach((element) => {
      const branchOffice = new AgentQuerySearchTravellerResultModel();
      branchOffice.SetData(element);
      toReturn.Travellers.push(branchOffice);
    });
    return toReturn;
  };
}

export default SearchService;
