import { Box, Flex, UseDisclosureReturn } from '@chakra-ui/react';
import { BaseIcon, BaseMenu, useAuth } from '@cksoftware/react-base';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApplicationRoles } from 'src/constants/applicationRoles';
import { getAxios } from 'src/lib/axios';
import endpoints from 'src/services/Common/endpoints';
import { JoinWorkflowModal as JoinWorkflowButton } from './JoinWorkflowModal';
import { WorkflowInitialJoinModel, WorkflowJoinStatus } from './Types';
import WorkflowList, { WorkListMode } from './WorkflowList';
import { faBars } from '@fortawesome/free-solid-svg-icons';

type Props = {
  disclosure: UseDisclosureReturn;
  setIsInWorkflow: (val: boolean) => void;
};
export default function WorkflowTool(props: Props) {
  const queryJoinStatus = useQuery<AxiosResponse<WorkflowJoinStatus, any>>({
    queryKey: ['WorkflowActivity', 'WorkingHours'],
    queryFn: () => {
      return getAxios().get<WorkflowJoinStatus>(endpoints.workingHours.endpointUrl, {
        params: { userTime: dayjs().utc() }
      });
    }
  });
  const mutateJoinStatus = useMutation({
    mutationFn: (data: WorkflowInitialJoinModel) => {
      return getAxios().post<void>(endpoints.workingHours.endpointUrl, data);
    },
    onSuccess: () => {
      queryJoinStatus.refetch().then();
    }
  });

  const local = useLocation();
  const [listMode, setListMode] = useState<WorkListMode>(WorkListMode.Max);
  const [isSpectating, setIsSpectating] = useState(false);
  const auth = useAuth();

  const [inWorkflow, setInWorkflow] = useState(false);

  useEffect(() => {
    if (queryJoinStatus.data?.data == WorkflowJoinStatus.Join) {
      setInWorkflow(true);
      props.setIsInWorkflow(true);
    } else if (
      queryJoinStatus.data?.data == WorkflowJoinStatus.Prompt &&
      auth.isInRole([ApplicationRoles.alwaysJoinWorkflow], false)
    ) {
      props.disclosure.onOpen();
    }
  }, [queryJoinStatus.data?.data]);

  if (queryJoinStatus.isPending || !queryJoinStatus.data?.data) {
    return <></>;
  }
  if (local.pathname.toLowerCase().includes('login')) {
    return <></>;
  }

  return (
    <Box backgroundColor={'#fafbff'} w={'100%'}>
      {queryJoinStatus.data.data == WorkflowJoinStatus.Join || isSpectating || inWorkflow ? (
        <Flex padding={'4px'} w={'100%'}>
          <WorkflowList mode={listMode} />
          <Box marginLeft={'auto'} alignSelf={'center'}>
            <BaseMenu
              items={[
                {
                  text: 'Min',
                  onClick: () => {
                    setListMode(WorkListMode.Min);
                  }
                },
                {
                  text: 'Max',
                  onClick: () => {
                    setListMode(WorkListMode.Max);
                  }
                },
                {
                  text: 'Hide',
                  onClick: () => {
                    setListMode(WorkListMode.Hide);
                  }
                },
                {
                  text: 'Leave',

                  onClick: () => {
                    setInWorkflow(false);
                    props.setIsInWorkflow(false);
                    setIsSpectating(false);
                  }
                }
              ]}>
              <BaseIcon icon={faBars} color={'black'} height={'20px'} p={'8px'} />
            </BaseMenu>
          </Box>
        </Flex>
      ) : (
        <JoinWorkflowButton
          disclosure={props.disclosure}
          joinStatus={queryJoinStatus.data.data}
          onJoin={(model) => {
            if (!model.IsSpectating) {
              mutateJoinStatus.mutate(model);
            } else {
              setIsSpectating(true);
              setInWorkflow(true);
              props.setIsInWorkflow(true);
            }
          }}
        />
      )}
    </Box>
  );
}
