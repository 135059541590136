import { ApplicationRoles } from 'src/constants/applicationRoles';
import applicationRoutes from 'src/constants/applicationRoutes';
import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import Layout from 'src/containers/layout/Layout';

export const AdminNavItems: RouteItem = {
  text: 'Admin',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [ApplicationRoles.userAdmin, ApplicationRoles.systemAdmin],
  layout: <Layout />,
  subItems: [
    {
      text: 'Manage Users',
      path: applicationRoutes.admin.users,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,

      allowedRoles: [ApplicationRoles.userAdmin],
      async lazy() {
        const component = await import('src-new/features/userAdmin/ManageUsers');
        return { Component: component.ManageUsers };
      }
    },
    {
      text: 'Manage OBT Users',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: applicationRoutes.admin.obtUsers,
      allowedRoles: [ApplicationRoles.userAdmin],
      async lazy() {
        const component = await import('src-new/features/userAdmin/ManageObtUsers');
        return { Component: component.ManageObtUsers };
      }
    },
    {
      text: 'Crm Management',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: applicationRoutes.admin.crmManagement,
      allowedRoles: [ApplicationRoles.systemAdmin],
      async lazy() {
        const component = await import('src/containers/admin/applicationManagement/crmAdmin/CrmManagementMain');
        return { Component: component.default };
      }
    },
    {
      text: 'Activity Management',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: applicationRoutes.admin.activityManagement,
      allowedRoles: [ApplicationRoles.systemAdmin],
      async lazy() {
        const component = await import(
          'src/containers/admin/applicationManagement/activityAdmin/ActivityManagementMain'
        );
        return { Component: component.default };
      }
    },
    {
      text: 'Loyalty Account Management',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: applicationRoutes.admin.loyaltyAccountManagement,
      allowedRoles: [ApplicationRoles.systemAdmin],
      async lazy() {
        const component = await import('src-new/features/crmAdmin/loyaltyNumbers/loyaltyNumberTypeManagement');
        return { Component: component.LoyaltyNumberTypeManagement };
      }
    },
    {
      text: 'Email/SMS Templates',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: applicationRoutes.admin.emailSignatures,
      allowedRoles: [ApplicationRoles.systemAdmin],
      async lazy() {
        const component = await import(
          'src/containers/admin/applicationManagement/emailSignatures/EmailSignatureManagement'
        );
        return { Component: component.default };
      }
    },
    {
      text: 'Announcements',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: applicationRoutes.admin.announcement,
      allowedRoles: [ApplicationRoles.systemAdmin],
      async lazy() {
        const component = await import(
          'src/containers/admin/applicationManagement/announcements/AnnouncementManagement'
        );
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.admin.passThrough,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.developer],
      async lazy() {
        const component = await import('src/containers/passthrough/ApiPassthrough');
        return { Component: component.ApiPassthrough };
      }
    }
  ]
};
