import { computed, observable, makeObservable } from 'mobx';
import EntityFormModel from '../forms/EntityFormModel';

class ProvincialTaxRateModel extends EntityFormModel {
  constructor() {
    super();
    makeObservable(this);
  }

  @observable
  // @ts-expect-error added by automation
  public Province: string;
  @observable
  // @ts-expect-error added by automation
  public ProvincialCode: string;
  @observable
  // @ts-expect-error added by automation
  public Gst: number;
  @observable
  // @ts-expect-error added by automation
  public Hst: number;
  @observable
  // @ts-expect-error added by automation
  public Pst: number;

  @computed
  public get TotalCalculatedTaxRate() {
    let value = 0;
    if (this.Hst > 0) {
      value = this.Hst;
    } else {
      value = this.Gst + this.Pst;
    }

    return value / 100;
  }

  @computed
  public get PstCalculatedTaxRate() {
    const value = this.Pst;
    return value / 100;
  }

  @computed
  public get GstOrHstCalculatedTaxRate() {
    let value = 0;
    if (this.Hst > 0) {
      value = this.Hst;
    } else {
      value = this.Gst;
    }
    return value / 100;
  }
}

export default ProvincialTaxRateModel;
