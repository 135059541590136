import { UseDisclosureReturn } from '@chakra-ui/react';
import { BaseFormDialog, useHookForm } from '@cksoftware/react-base';
import { TimeOptionsSelect } from 'src-new/features/WorkflowTool/Utils.tsx';

type Props = {
  disclosure: UseDisclosureReturn;
  onSubmit: (message: string, time: string) => void;
};

type ReturnTimeForm = {
  message: string;
  time: string;
};

export const WorkflowReturnTimeDialog = ({ disclosure, onSubmit }: Props) => {
  const { formMethods, fieldHelper } = useHookForm<ReturnTimeForm>();

  const fields = [
    fieldHelper.textInput('message').withLabel('Message'),
    fieldHelper
      .selectInput('time', { items: TimeOptionsSelect() })
      .withLabel('Return Time')
      .withInputProps({ disableSort: true })
  ];
  return (
    <BaseFormDialog
      disclosure={disclosure}
      formMethods={formMethods}
      fields={fields}
      isLoading={false}
      onSubmit={(data) => {
        onSubmit(data.message, data.time);
      }}
      header={'Set Away Status'}></BaseFormDialog>
  );
};
