import { Badge, Box, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { useActivityInformationTravellersQuery } from 'src-new/features/activites/informationDisplays/api/useActivityInformationTravellersQuery';
import { InformationAlertDisplay } from 'src-new/features/activites/informationDisplays/components/informationAlertDisplay';
import { faBank, faBuilding, faReceipt, faStar, faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons';
import { userColorSchemes } from 'src-new/constants/activityColorSchemes';
import { BaseCard, BaseIcon, BaseSkeletonStack } from '@cksoftware/react-base';
import { LabelTextDisplay } from 'src-new/components/Common/LabelTextDisplay.tsx';
import { ActivityInformationTravellerContactInfoBar } from 'src-new/features/activites/informationDisplays/components/activityInformationTravellerContactInfoBar.tsx';
import { PreTextDisplay } from 'src-new/components/Util/PreTextDisplay.tsx';

type Props = {
  travellerIds: number[];
  authorizedBookerId: number | undefined;
  approvedBookerId: number | undefined;
  displayCompany?: boolean;
};

export const ActivityInformationTravellerDisplay = (props: Props) => {
  const query = useActivityInformationTravellersQuery(
    [...props.travellerIds, props.approvedBookerId, props.authorizedBookerId].filter((val) => val != undefined)
  );

  if (query.isLoading) {
    return <BaseSkeletonStack />;
  }
  if (!query.data) {
    return <></>;
  }

  return (
    <BaseCard maxHeight={'90vh'} header={'Travellers'} w={'100%'}>
      <SimpleGrid columns={2} gap={'8px'}>
        {query.data.map((val, key) => {
          return (
            <BaseCard
              variant={'subtle'}
              key={key}
              header={
                <Flex flexDir={'column'}>
                  <Heading paddingTop={0} marginTop={0}>
                    <Flex gap={'4px'} whiteSpace={'normal'} flexWrap={'wrap'}>
                      {val.FirstName} {val.LastName}
                      {val.IsVip && (
                        <Badge colorPalette={userColorSchemes.vip}>
                          <BaseIcon toolTip={'Is a VIP'} icon={faStar}>
                            VIP{' '}
                          </BaseIcon>
                        </Badge>
                      )}
                      {val.HasObtAccess && (
                        <Badge colorPalette={userColorSchemes.obt}>
                          OBT {val.IsObtSuperUser ? <>Super User</> : <>Access</>}
                        </Badge>
                      )}
                      {val.IsAuthorizedBooker && <Badge colorPalette={userColorSchemes.authBooker}>Auth. Booker</Badge>}
                    </Flex>
                  </Heading>
                  {val.Title && (
                    <Text fontSize={'12px'} lineHeight={'10px'}>
                      {val.Title}
                    </Text>
                  )}
                </Flex>
              }
              w={'100%'}
              display={'inline-block'}>
              <>
                <Box> {props.displayCompany && <i>{val.CompanyName}</i>}</Box>
                <ActivityInformationTravellerContactInfoBar contact={val} />
                <Box>
                  <LabelTextDisplay
                    label={'Auth Booker'}
                    hideIfTextNull={true}
                    render={() => {
                      return <PreTextDisplay>{val.AuthorizedBookerInformation}</PreTextDisplay>;
                    }}
                    text={val.AuthorizedBookerInformation}
                  />
                </Box>
                <Flex flexDir={'column'} alignItems={'flex-start'}>
                  {val.BranchOffice && (
                    <BaseIcon toolTip={'Branch Office'} icon={faBuilding}>
                      <Box> {val.BranchOffice}</Box>
                    </BaseIcon>
                  )}

                  {val.PreferredPaymentMethods && val.PreferredPaymentMethods.length > 0 && (
                    <BaseIcon toolTip={'Preferred Payment'} icon={faBank}>
                      <Box> {val.PreferredPaymentMethods.join(', ')}</Box>
                    </BaseIcon>
                  )}
                  {val.GdsCardLastFour && (
                    <BaseIcon toolTip={'GDS Card On File'} icon={faBank}>
                      <Box> GDS Card On File: {val.GdsCardLastFour}</Box>
                    </BaseIcon>
                  )}
                  {val.PersonalGdsCardLastFour && (
                    <BaseIcon toolTip={'Personal GDS Card'} icon={faUmbrellaBeach}>
                      <Box> Personal Travel Card On File: {val.GdsCardLastFour}</Box>
                    </BaseIcon>
                  )}
                  {val.LoyaltyNumbers &&
                    val.LoyaltyNumbers.map((val, key) => {
                      return (
                        <BaseIcon key={key} toolTip={'Loyalty Number'} icon={faReceipt}>
                          <Box>
                            {val.Type} - {val.AccountNumber}
                          </Box>
                        </BaseIcon>
                      );
                    })}
                </Flex>
                <Flex flexDir={'column'} gap={'4px'}>
                  <InformationAlertDisplay toDisplay={val.CodeRedNotes} status={'error'} header={'Code Red'} />

                  <InformationAlertDisplay toDisplay={val.AgentNotes} status={'warning'} header={'Agent Notes'} />

                  <InformationAlertDisplay
                    toDisplay={val.PersonalPreferences ? [val.PersonalPreferences] : []}
                    status={'info'}
                    header={'Personal Preferences'}
                  />
                </Flex>
              </>
            </BaseCard>
          );
        })}
      </SimpleGrid>
    </BaseCard>
  );
};
