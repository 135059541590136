import { action, runInAction, makeObservable } from 'mobx';
import { CrmFormHelperModel } from '../../models/crm/CrmFormHelperModel';
import { Amenity } from '../../models/hotel/Amenity';
import CrmStore from '../../stores/crmStore';
import endpoints from '../Common/endpoints';
import ServiceBase from '../Common/ServiceBase';

class CrmFormHelperService extends ServiceBase {
  constructor() {
    super(endpoints.crm.hotelCrmFormHelper);
    makeObservable(this);
  }

  @action
  public loadRoomTypes = async (store: CrmStore) => {
    const response = await this.get<CrmFormHelperModel>();

    runInAction(() => {
      store.Amenities.replace(
        response.data.Amenities.map((x) => {
          const amenity = new Amenity();
          amenity.SetData(x);
          return amenity;
        })
      );

      // @ts-expect-error added by automation
      const uniqueResponse = [];
      response.data.RoomTypes.forEach((roomType) => {
        if (roomType) {
          const trimVal = roomType.trim();
          // @ts-expect-error added by automation
          if (!uniqueResponse.includes(trimVal)) {
            uniqueResponse.push(trimVal);
          }
        }
      });
      // @ts-expect-error added by automation
      store.RoomTypes.replace(uniqueResponse.sort());
    });
  };

  @action
  public loadFormData = async (store: CrmStore) => {
    const response = await this.get<CrmFormHelperModel>();

    runInAction(() => {
      store.Amenities.replace(
        response.data.Amenities.map((x) => {
          const amenity = new Amenity();
          amenity.SetData(x);
          return amenity;
        })
      );
      store.BookingTypes.replace(response.data.BookingTypes);
      store.PreferredPaymentMethods.replace(response.data.PreferredPaymentMethods);
      store.ReportingRequirements.replace(response.data.ReportingRequirements);
      store.RoomTypes.replace(response.data.RoomTypes);
      store.LoyaltyAccountTypes.replace(response.data.LoyaltyAccountTypes);
      store.Cities.replace(response.data.Cities);
      store.CitiesWithProvince.replace(response.data.CitiesWithProvince);
      store.DailyHotelRates.replace(response.data.DailyHotelRates);
    });
  };
}

export default CrmFormHelperService;
