import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import * as Sentry from '@sentry/react';
import { QueryClient, UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { InvokeRpc, ServerRpc } from './types';

interface QueryClientRpc {
  [key: string]: (rq: QueryClient, ...args: any[]) => any;
}

export type ReactQueryHubReturn<T extends ServerRpc<T>, J> = [UseQueryResult<J, unknown>, InvokeRpc<T>];

//TODO write overload that returns query keys and is not type bound to one cache slot
//this version is good for websockets that share a model/work on one model, overload would enable managing multiple datapoints
export default function useReactQueryHub<T extends ServerRpc<T>, J>(
  url: string,
  client: QueryClientRpc,
  key: string
): ReactQueryHubReturn<T, J> {
  const queryClient = useQueryClient();
  // @ts-expect-error added by automation
  const [connection, setConnection] = useState<HubConnection>(null);
  useEffect(() => {
    return setupConnection(url, client, queryClient, setConnection);
  }, []);
  return [
    //note that since we set data else where this will actually resolve
    useQuery<J>({ queryKey: [key], queryFn: () => new Promise<J>(() => {}) }, queryClient),
    // @ts-expect-error added by automation
    connection?.state == HubConnectionState.Connected
      ? (methodName, ...args) => {
          return connection?.invoke(methodName.toString(), ...args);
        }
      : undefined
  ];
}

function setupConnection(
  url: string,
  client: QueryClientRpc,
  queryClient: QueryClient,
  callback: (conn: HubConnection) => void
) {
  const initConnection = new HubConnectionBuilder()
    .withUrl(url)
    .withAutomaticReconnect([0, 2000, 10000, 30000, 60000, 120000])
    .build();

  //setting up rpc called based on param
  const functions = Object.keys(client);
  for (const procedure of functions) {
    initConnection.on(procedure, (...args) => {
      client[procedure](queryClient, ...args);
    });
  }
  initConnection
    .start()
    .then(() => {
      callback(initConnection);
    })
    .catch((e) => {
      Sentry.captureException(e);
    });

  return () => {
    initConnection.stop().catch((e) => {
      Sentry.captureException(e);
    });
  };
}
