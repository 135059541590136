import { observable, makeObservable, computed } from 'mobx';

import EntityFormModel from '../forms/EntityFormModel';
import { getFormattedServerDate } from '../../utils/helpers/dateHelpers';

class FileModel extends EntityFormModel {
  constructor(fileName?: string, fileHandle?: string, id?: number, isFolio?: boolean, createdOn?: string) {
    super();
    // @ts-expect-error added by automation
    this.FileHandle = fileHandle;
    // @ts-expect-error added by automation
    this.FileName = fileName;
    // @ts-expect-error added by automation
    this.Id = id;
    // @ts-expect-error added by automation
    this.IsFolio = isFolio;
    // @ts-expect-error added by automation
    this.CreatedOn = createdOn;
    makeObservable(this);
  }

  @observable
  public FileName: string;
  @observable
  public FileHandle: string;
  @observable
  public IsFolio: boolean;
  @observable
  public CreatedOn: string;

  @computed
  public get CreatedOnFormatted(): string {
    return getFormattedServerDate(this.CreatedOn);
  }

  public GetExtension(): string {
    const indexOfDot = this.FileName.lastIndexOf('.');
    if (indexOfDot == -1 || indexOfDot == this.FileName.length - 1) {
      return 'n/a';
    }
    return this.FileName.substring(indexOfDot + 1).toLowerCase();
  }
}

export default FileModel;
