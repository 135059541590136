import { getAxios } from 'src/lib/axios';
import ActivityNoteModel from '../../models/activities/ActivityNoteModel';
import ClientEmailAttachmentModel from '../../models/activities/ClientEmailAttachmentModel';
import endpoints from '../../services/Common/endpoints';
import { getFormattedUrl } from './stringHelpers';
import { ClientEmailAttachment } from 'src-new/features/inbox/types/Activity';

const downloadTextFile = async (url: string): Promise<string> => {
  const axios = getAxios();
  const response = await axios.get<string>(url, { responseType: 'text' });
  return response.data;
};

export const downloadFile = async (url: string, fileName: string) => {
  const axios = getAxios();
  const response = await axios.get<Blob>(url, { responseType: 'blob' });
  return downloadObjectBlob(response.data, fileName);
};

export const downloadObjectBlob = async (blob: Blob, fileName: string) => {
  const anchor = document.createElement('a');

  const objectUrl = window.URL.createObjectURL(blob);
  anchor.href = objectUrl;
  anchor.download = fileName;
  anchor.click();
  window.URL.revokeObjectURL(objectUrl);
};

const pdfFile = async (url: string) => {
  const axios = getAxios();
  const response = await axios.get<Blob>(url, {
    responseType: 'blob'
  });
  const objectUrl = window.URL.createObjectURL(response.data);
  return objectUrl;
};

export const activityDownloadFile = async (
  item: ActivityNoteModel | ClientEmailAttachmentModel | ClientEmailAttachment
) => {
  await downloadFile(
    getFormattedUrl(endpoints.dataAccess.getFile, {
      fileHandle: item.FileHandle
    }),
    item.FileName
  );
};

export const downloadCrmFile = async (
  fileHandle: string, fileName: string
) => {
  await downloadFile(
    getFormattedUrl(endpoints.dataAccess.getFile, {
      fileHandle: fileHandle
    }),
    fileName
  );
};

export const activityDisplayPDF = (fileHandle: string) => {
  return pdfFile(
    getFormattedUrl(endpoints.dataAccess.getFile, {
      fileHandle: fileHandle
    })
  );
};

export const activityDownloadFileText = async (fileHandle: string): Promise<string> => {
  const txt: string = await downloadTextFile(
    getFormattedUrl(endpoints.dataAccess.getFile, {
      fileHandle: fileHandle
    })
  );
  return txt;
};
