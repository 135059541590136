import { create } from 'zustand';
import { DisclosureLike, DispatchLike } from './types';

export enum ActivityFooterTabState {
  CompanySearch,
  HotelSearch,
  TravellersSearch,
  Company,
  Travellers,
  HotelInfo
}

export const ActivityFooterOrder: Array<ActivityFooterTabState> = [
  ActivityFooterTabState.Company,
  ActivityFooterTabState.Travellers,
  ActivityFooterTabState.HotelInfo,
  ActivityFooterTabState.CompanySearch,
  ActivityFooterTabState.TravellersSearch,
  ActivityFooterTabState.HotelSearch
];

type Store = {
  index: number; //this is more like the target index
  setIndex: (x: number) => void;
  height: number;
  setHeight: DispatchLike<number>;
  reset: () => void;
  openTab: (tab: ActivityFooterTabState, noReset?: boolean) => void;
  noReset: boolean; //for interpage opening
};
export const useActivityFooterStore = create<Store & DisclosureLike>((set) => ({
  reset() {
    set((state) => {
      if (state.noReset) {
        return { noReset: false };
      }
      return { open: false };
    });
  },
  openTab(tab, noReset) {
    const index = ActivityFooterOrder.indexOf(tab);
    if (index == -1) {
      return;
    }
    set(() => ({ open: true, index, noReset: noReset ?? false }));
  },
  onToggle() {
    set((state) => ({ open: !state.open }));
  },
  onClose() {
    set(() => ({ open: false }));
  },
  onOpen() {
    set(() => ({ open: true }));
  },
  setOpen(_: any) {},
  setIndex(x) {
    set(() => ({ index: x }));
  },
  setHeight(x) {
    if (typeof x == 'number') {
      set(() => ({ height: x }));
    } else {
      set((state) => ({ height: x(state.height) }));
    }
  },
  open: false,
  index: 0,
  height: 200, //TODO set const
  noReset: false
}));
