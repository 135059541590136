import { computed, makeObservable } from 'mobx';
import ActivityAlertLevel from '../../enums/activities/ActivityAlertLevel';
import { ActivitySort } from '../../enums/activities/ActivitySort';
import ActivityStatus from '../../enums/activities/ActivityStatus';
import ActivityPagingModel from '../../models/activities/ActivityPagingModel';
import ActivitySortFilterModel from '../../models/activities/ActivitySortFilterModel';
import { IEmailActivityOverviewModel } from '../../models/activities/IEmailActivityOverviewModel';
import { getDateObjectByName, sortModelsByDate } from '../helpers/dateHelpers';

class ActivityOverviewSortProvider {
  public ActivitySortFilterModel: ActivitySortFilterModel;
  private pagingModel: ActivityPagingModel;

  public constructor(pagingModel: ActivityPagingModel) {
    this.pagingModel = pagingModel;
    makeObservable(this);
    this.ActivitySortFilterModel = new ActivitySortFilterModel();
  }

  @computed
  public get SortedActivities() {
    let sorted: Array<IEmailActivityOverviewModel>;

    switch (this.ActivitySortFilterModel.ActivitySort) {
      case ActivitySort.DueDate:
        sorted = this.sortActivitiesByDueDate(this.pagingModel.Activities);
        break;
      case ActivitySort.EndDate:
        sorted = this.sortActivitiesByEndDate(this.pagingModel.Activities);
        break;
      case ActivitySort.StartDate:
        sorted = this.sortActivitiesByStartDate(this.pagingModel.Activities);
        break;
      case ActivitySort.Priority:
        sorted = this.sortActivitiesByPriority(this.pagingModel.Activities);
        break;
      case ActivitySort.CreatedOn:
        sorted = this.sortActivitiesByCreatedOn(this.pagingModel.Activities, true);
        break;
      case ActivitySort.AssignedUser:
        sorted = this.sortActivitiesByAssigned(this.pagingModel.Activities);
        break;
      case ActivitySort.From:
        sorted = this.sortActivitiesByFrom(this.pagingModel.Activities);
        break;
      case ActivitySort.Subject:
        sorted = this.sortActivitiesBySubject(this.pagingModel.Activities);
        break;
      case ActivitySort.Comments:
        sorted = this.sortActivitiesByComment(this.pagingModel.Activities);
        break;
      case ActivitySort.HotelName:
        sorted = this.sortActivitiesByHotel(this.pagingModel.Activities);
        break;
      case ActivitySort.CompanyName:
        sorted = this.sortActivitiesByCompany(this.pagingModel.Activities);
        break;
      case ActivitySort.TravellerNames:
        sorted = this.sortActivitiesByFirstTraveller(this.pagingModel.Activities);
        break;
      case ActivitySort.ClientName:
        //sorted = this.sortActivitiesByCustomerName(activities);
        break;
      case ActivitySort.CompanyNameFLSQ:
        sorted = this.sortActivitiesByFirstLetterAndId(this.pagingModel.Activities, true, 'CompanyName');
        break;
      default:
        sorted = [];
    }
    if (this.ActivitySortFilterModel.SortAscending) {
      // @ts-expect-error added by automation
      return sorted.reverse();
    }
    // @ts-expect-error added by automation
    return sorted;
  }

  // private sortActivitiesByCreatedOn = (array: Array<IEmailActivityOverviewModel>) => {
  //   return array.slice().sort((a, b) => sortModelsByDate(a, b, 'ActivityCreatedOn', true));
  // };
  private sortActivitiesByStartDate = (array: Array<IEmailActivityOverviewModel>) => {
    return array.slice().sort((a, b) => sortModelsByDate(a, b, 'StartDate', true));
  };
  private sortActivitiesByEndDate = (array: Array<IEmailActivityOverviewModel>) => {
    return array.slice().sort((a, b) => sortModelsByDate(a, b, 'EndDate', true));
  };

  private sortActivitiesByAssigned = (array: Array<IEmailActivityOverviewModel>) => {
    return array
      .slice()
      .sort(
        (a, b) =>
          this.sortByUserName(a, b, 'AssignedUserName') ||
          sortModelsByDate(a, b, 'LatestEmailDate', true) ||
          sortModelsByDate(a, b, 'ActivityCreatedOn', true) ||
          sortModelsByDate(a, b, 'DueDate')
      );
  };

  private sortActivitiesByFrom = (array: Array<IEmailActivityOverviewModel>, sortDescending: boolean = false) => {
    return array.slice().sort(function (a, b) {
      // @ts-expect-error added by automation
      let aStr: string = null;
      // @ts-expect-error added by automation
      let bStr: string = null;
      if (a?.Subject != null) {
        aStr = a.Subject.charAt(0);
      }
      if (b?.Subject != null) {
        bStr = b.Subject.charAt(0);
      }
      if (aStr == null && bStr == null) {
        return 0;
      }
      if (aStr == null) {
        return sortDescending ? -1 : 1;
      }
      if (bStr == null) {
        return sortDescending ? 1 : -1;
      }
      return aStr.localeCompare(bStr);
    });
  };

  private sortActivitiesByFirstLetterAndId = (
    array: Array<IEmailActivityOverviewModel>,
    sortDescending: boolean = false,
    name: string
  ): Array<IEmailActivityOverviewModel> => {
    return array.slice().sort((a, b) => {
      // @ts-expect-error added by automation
      let aStr: string = null;
      // @ts-expect-error added by automation
      let bStr: string = null;
      // @ts-expect-error added by automation
      if (a[name] != null) {
        // @ts-expect-error added by automation
        aStr = a[name].charAt(0);
      }
      // @ts-expect-error added by automation
      if (b[name] != null) {
        // @ts-expect-error added by automation
        bStr = b[name].charAt(0);
      }
      if (aStr == null && bStr == null) {
        // If both are null, sort by HotelBookingQuoteInquiryId
        if (a.HotelBookingQuoteInquiryId == null && b.HotelBookingQuoteInquiryId == null) {
          return 0;
        }
        if (a.HotelBookingQuoteInquiryId == null) {
          return sortDescending ? -1 : 1;
        }
        if (b.HotelBookingQuoteInquiryId == null) {
          return sortDescending ? 1 : -1;
        }
        return sortDescending
          ? b.HotelBookingQuoteInquiryId - a.HotelBookingQuoteInquiryId
          : a.HotelBookingQuoteInquiryId - b.HotelBookingQuoteInquiryId;
      }
      if (aStr == null) {
        return sortDescending ? -1 : 1;
      }
      if (bStr == null) {
        return sortDescending ? 1 : -1;
      }

      const primaryComparison = sortDescending ? bStr.localeCompare(aStr) : aStr.localeCompare(bStr);

      if (primaryComparison === 0) {
        // If first letters are the same, sort by HotelBookingQuoteInquiryId
        if (a.HotelBookingQuoteInquiryId == null && b.HotelBookingQuoteInquiryId == null) {
          return 0;
        }
        if (a.HotelBookingQuoteInquiryId == null) {
          return 1;
        }
        if (b.HotelBookingQuoteInquiryId == null) {
          return -1;
        }
        return b.HotelBookingQuoteInquiryId - a.HotelBookingQuoteInquiryId;
      }

      return primaryComparison;
    });
  };

  private sortActivitiesByFirstLetter = (
    array: Array<IEmailActivityOverviewModel>,
    sortDescending: boolean = false,
    name: string
  ) => {
    return array.slice().sort(function (a, b) {
      // @ts-expect-error added by automation
      let aStr: string = null;
      // @ts-expect-error added by automation
      let bStr: string = null;
      // @ts-expect-error added by automation
      if (a[name] != null) {
        // @ts-expect-error added by automation
        aStr = a[name].charAt(0);
      }
      // @ts-expect-error added by automation
      if (b[name] != null) {
        // @ts-expect-error added by automation
        bStr = b[name].charAt(0);
      }
      if (aStr == null && bStr == null) {
        return 0;
      }
      if (aStr == null) {
        return sortDescending ? -1 : 1;
      }
      if (bStr == null) {
        return sortDescending ? 1 : -1;
      }
      return aStr.localeCompare(bStr);
    });
  };

  private sortActivitiesByFirstTraveller = (
    array: Array<IEmailActivityOverviewModel>,
    sortDescending: boolean = false
  ) => {
    return array.slice().sort(function (a, b) {
      // @ts-expect-error added by automation
      let aStr: string = null;
      // @ts-expect-error added by automation
      let bStr: string = null;
      if (a.TravellerNames.length > 0) {
        aStr = a.TravellerNames[0].charAt(0);
      }
      if (b.TravellerNames.length > 0) {
        bStr = b.TravellerNames[0].charAt(0);
      }
      if (aStr == null && bStr == null) {
        return 0;
      }
      if (aStr == null) {
        return sortDescending ? -1 : 1;
      }
      if (bStr == null) {
        return sortDescending ? 1 : -1;
      }
      return aStr.localeCompare(bStr);
    });
  };

  private sortActivitiesByHotel = (array: Array<IEmailActivityOverviewModel>, sortDescending: boolean = false) => {
    return this.sortActivitiesByFirstLetter(array, sortDescending, 'HotelName');
  };
  private sortActivitiesByCompany = (array: Array<IEmailActivityOverviewModel>, sortDescending: boolean = false) => {
    return this.sortActivitiesByFirstLetter(array, sortDescending, 'CompanyName');
  };
  private sortActivitiesBySubject = (array: Array<IEmailActivityOverviewModel>, sortDescending: boolean = false) => {
    return this.sortActivitiesByFirstLetter(array, sortDescending, 'Subject');
  };

  private sortActivitiesByComment = (array: Array<IEmailActivityOverviewModel>, sortDescending: boolean = false) => {
    return array.slice().sort(function (a, b) {
      const aStr = !!a.PriorityNote ? a.PriorityNote?.charAt(0) : null;
      const bStr = !!b.PriorityNote ? b.PriorityNote?.charAt(0) : null;
      if (aStr == null && bStr == null) {
        return 0;
      }
      if (aStr == null) {
        return sortDescending ? -1 : 1;
      }
      if (bStr == null) {
        return sortDescending ? 1 : -1;
      }
      return aStr.localeCompare(bStr);
    });
  };

  private sortActivitiesByDueDate = (array: Array<IEmailActivityOverviewModel>) => {
    return array
      .slice()
      .sort(
        (a, b) =>
          sortModelsByDate(a, b, 'LatestEmailDate', true) ||
          sortModelsByDate(a, b, 'InitialEmailDate', true) ||
          sortModelsByDate(a, b, 'ActivityCreatedOn', true)
      );
  };

  private sortActivitiesByCreatedOn = (array: Array<IEmailActivityOverviewModel>, sortDescending: boolean = false) => {
    const specialStatuses = [ActivityStatus.Waiting, ActivityStatus.Holding];
    return array.slice().sort((a, b) => {
      if (a.NewReply) {
        return sortDescending ? -1 : 1; // Place objects with status 'Waiting' last
      } else if (b.NewReply) {
        return sortDescending ? 1 : -1;
      } else if (a.Status === ActivityStatus.Holding && b.Status === ActivityStatus.Waiting) {
        return 1; // Sort "Holding" lower than "Waiting"
      } else if (a.Status === ActivityStatus.Waiting && b.Status === ActivityStatus.Holding) {
        return -1; // Sort "Waiting" higher than "Holding"
      } else if (specialStatuses.includes(a.Status)) {
        return 1;
      } else if (specialStatuses.includes(b.Status)) {
        return -1;
      } else if (a.ActivityCreatedOn == null && b.ActivityCreatedOn == null) {
        return 0;
      } else if (a.ActivityCreatedOn == null) {
        return sortDescending ? -1 : 1;
      } else if (b.ActivityCreatedOn == null) {
        return sortDescending ? 1 : -1;
      }
      const aDate = getDateObjectByName(a, 'ActivityCreatedOn');
      const bDate = getDateObjectByName(b, 'ActivityCreatedOn');
      if (sortDescending) {
        // @ts-expect-error added by automation
        return bDate.diff(aDate);
      }
      // @ts-expect-error added by automation
      return aDate.diff(bDate);
    });
  };

  private sortActivitiesByPriority = (array: Array<IEmailActivityOverviewModel>) => {
    const arraySliced = array.slice();
    const specialStatuses = [ActivityStatus.Waiting, ActivityStatus.Holding];
    const specialStatusList = this.sortByPriorityInternal(
      arraySliced.filter((a) => specialStatuses.includes(a.Status))
    );
    const newReply = this.sortByPriorityInternal(
      arraySliced.filter((a) => a.NewReply && !specialStatuses.includes(a.Status))
    );
    const rest = this.sortByPriorityInternal(
      arraySliced.filter((a) => !a.NewReply && !specialStatuses.includes(a.Status))
    );
    return [...newReply, ...rest, ...specialStatusList];
  };

  private sortByPriorityInternal = (array: Array<IEmailActivityOverviewModel>) => {
    const order = [
      ActivityAlertLevel.None,
      ActivityAlertLevel.Upcoming,
      ActivityAlertLevel.Tomorrow,
      ActivityAlertLevel.Today,
      ActivityAlertLevel.Urgent
    ];

    return array.sort((a, b) => {
      const indexA = order.indexOf(a.AlertLevel);
      const indexB = order.indexOf(b.AlertLevel);
      return indexB - indexA; // Sort based on AlertLevel
    });
  };

  private sortByUserName = <T extends keyof IEmailActivityOverviewModel>(
    a: IEmailActivityOverviewModel,
    b: IEmailActivityOverviewModel,
    propertyName: T,
    sortDescending: boolean = false
    // @ts-expect-error added by automation
  ): number => {
    if (a[propertyName] == null && b[propertyName] == null) {
      return 0;
    }
    if (a[propertyName] == null) {
      return sortDescending ? -1 : 1;
    }
    if (b[propertyName] == null) {
      return sortDescending ? 1 : -1;
    }
    if (a[propertyName] < b[propertyName]) {
      return -1;
    }
    if (a[propertyName] > b[propertyName]) {
      return 1;
    }
  };

  private sortByAlertLevel = <T extends keyof IEmailActivityOverviewModel>(
    a: IEmailActivityOverviewModel,
    b: IEmailActivityOverviewModel,
    propertyName: T,
    sortDescending: boolean = false
  ): number => {
    if (a[propertyName] == ActivityAlertLevel.Today && b[propertyName] == ActivityAlertLevel.Upcoming) {
      return 0;
    }
    if (a[propertyName] == ActivityAlertLevel.Upcoming && b[propertyName] == ActivityAlertLevel.Today) {
      return 0;
    }
    if (a[propertyName] == null && b[propertyName] == null) {
      return 0;
    }
    if (a[propertyName] == null) {
      return sortDescending ? -1 : 1;
    }
    if (b[propertyName] == null) {
      return sortDescending ? 1 : -1;
    }
    return b.AlertLevel - a.AlertLevel;
  };
}

export default ActivityOverviewSortProvider;
