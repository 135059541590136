import { ApplicationRoles } from 'src/constants/applicationRoles';
import applicationRoutes from 'src/constants/applicationRoutes';
import Layout from 'src/containers/layout/Layout';
import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';

export const CrmNavItems: RouteItem = {
  text: 'CRM',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [ApplicationRoles.crm],
  layout: <Layout />,
  subItems: [
    {
      text: 'Manage Hotels',
      path: applicationRoutes.crm.hotel.list,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.crm],
      async lazy() {
        const component = await import('src/containers/crm/hotel/HotelManagement');
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.crm.company.list,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.crm],
      async lazy() {
        const component = await import('src/containers/crm/company/CompanyManagement');
        return { Component: component.default };
      }
    },
    {
      text: 'Manage Companies',
      path: applicationRoutes.crm.companiesNew.list,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.crm],
      async lazy() {
        const component = await import('src-new/features/company/overview/CompanyManagement');
        return { Component: component.CompanyManagement };
      }
    },
    {
      text: 'Manage Leads (Legacy)',
      path: applicationRoutes.crm.company.list,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.crm],
      async lazy() {
        const component = await import('src/containers/crm/company/CompanyManagement');
        return { Component: component.default };
      }
    },
    {
      text: 'Manage Leads',
      path: applicationRoutes.crm.leads.list,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.crm],
      async lazy() {
        const component = await import('src-new/features/leads/LeadManagement');
        return { Component: component.LeadManagement };
      }
    },

    {
      path: applicationRoutes.crm.hotel.add,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.crm],
      async lazy() {
        const component = await import('src/containers/crm/hotel/AddHotelForm');
        return { Component: component.default };
      }
    },

    {
      path: applicationRoutes.crm.hotel.edit + '/:id',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.crm],
      async lazy() {
        const component = await import('src/containers/crm/hotel/EditHotelForm');
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.crm.company.add,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.crm],
      async lazy() {
        const component = await import('src/containers/crm/company/AddCompanyForm');
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.crm.company.edit + '/:id',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.crm],
      async lazy() {
        const component = await import('src/containers/crm/company/EditCompany/EditCompanyForm');
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.crm.companyProject.email,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.crm],
      async lazy() {
        const component = await import('src/containers/crm/companyProjects/GroupEmail/GroupEmailLayoutContainer');
        return { Component: component.default };
      }
    },

    {
      path: applicationRoutes.crm.companyProject.edit + '/:id',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.crm],
      async lazy() {
        const component = await import('src/containers/crm/companyProjects/EditGroupForm');
        return { Component: component.default };
      }
    },
    {
      path: applicationRoutes.crm.companyProject.dashboard,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [ApplicationRoles.crm],
      async lazy() {
        const component = await import('src/containers/crm/companyProjects/GroupDashboard');
        return { Component: component.default };
      }
    }
  ]
};
