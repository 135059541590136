import { PropsWithChildren } from 'react';
import { Heading } from '@chakra-ui/react';

export const ModalHeaderLegacy = (props: PropsWithChildren) => {
  return (
    <Heading paddingTop={'12px'} paddingBottom={'4px'}>
      {props.children}
    </Heading>
  );
};
