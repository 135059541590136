import { observer } from 'mobx-react-lite';
import * as React from 'react';
import FormWrapper from '../../app/form/FormWrapper';
import { useUtilityStore } from '../../stores/useMobxStores';
import styled from '../../utils/styled/styled';
import { ClsDefaultButton, ClsSubmitButton } from '../base/buttons';
import { Heading } from '@chakra-ui/react';

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 4px;
  justify-content: center;
`;

interface ICallout extends React.PropsWithChildren {
  header?: string;
  cancelCallback?: () => void;
  okCallback?: () => void;
  okButtonText?: string;
  closeButtonText?: string;
  hideCloseButton?: boolean;
  disableOkButton?: boolean;
}

const ConfirmationCallout = (props: React.PropsWithChildren<ICallout>) => {
  const store = useUtilityStore();

  const onOk = React.useCallback(() => {
    if (props.okCallback) {
      props.okCallback();
    }
    store.calloutState.close();
  }, [store.calloutState, props]);

  const onCancel = React.useCallback(() => {
    if (props.cancelCallback) {
      props.cancelCallback();
    }
    store.calloutState.close();
  }, [store.calloutState, props]);

  return (
    <FormWrapper onSubmit={onOk}>
      <Heading p={'6px 12px 0px 8px'}>{props.header}</Heading>
      {props.children}
      <ButtonGroup>
        <ClsSubmitButton disabled={props.disableOkButton} text={props.okButtonText ?? 'Ok'} onClick={onOk} />
        {props.hideCloseButton ? null : <ClsDefaultButton text={props.closeButtonText ?? 'No'} onClick={onCancel} />}
      </ButtonGroup>
    </FormWrapper>
  );
};

export default observer(ConfirmationCallout);
