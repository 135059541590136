import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';

type ActivityInformationContextType = {
  setActivityId: Dispatch<SetStateAction<number | undefined>>;
  activityId: number | undefined;
};

export const ActivityInformationContext = createContext<ActivityInformationContextType | null>(null);

export function ActivityInformationContextProvider(props: PropsWithChildren) {
  const [activityId, setActivityId] = useState<number | undefined>(undefined);
  return (
    <ActivityInformationContext.Provider
      value={{
        activityId: activityId,
        setActivityId: setActivityId
      }}>
      {props.children}
    </ActivityInformationContext.Provider>
  );
}
