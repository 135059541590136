import { defineSlotRecipe } from '@chakra-ui/react';
import { alertAnatomy } from '@chakra-ui/react/anatomy';

export const alertSlotRecipe = defineSlotRecipe({
  className: 'chakra-alert',
  slots: alertAnatomy.keys(),
  defaultVariants: {
    variant: 'standard'
  },
  variants: {
    buttonAlert: {
      true: {
        description: {
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          gap: '4px'
        }
      }
    },
    variant: {
      standard: {
        root: {
          bg: 'colorPalette.100',
          color: 'var(--chakra-colors-fg)',
          textAlign: 'left'
        },
        description: {
          fontWeight: 'medium',
          fontSize: '14px'
        },
        indicator: {
          color: 'colorPalette.solid'
        }
      }
    },
    size: {
      md: {
        root: {
          gap: '4px',
          px: '12px',
          py: '4px',
          textStyle: 'md'
        },
        indicator: {
          textStyle: 'md'
        },
        description: {
          fontSize: 'md'
        }
      },
      xs: {
        root: {
          gap: '3px',
          px: '8px',
          py: '2px',
          textStyle: 'md'
        },
        indicator: {
          textStyle: 'md'
        }
      }
    }
  }
});
