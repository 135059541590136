import { AddressV2 } from 'src-new/globalModels/locations/address';

export function getFullAddressString(address: AddressV2) {
  const addressStringArray = getAddressStringArray(address);
  return addressStringArray.filter((val) => val != null).join(', ');
}

export function getAddressStringArray(address: AddressV2) {
  const toReturn = new Array<string>();
  if (address.AddressLineOne) {
    toReturn.push(address.AddressLineOne);
  }
  if (address.AddressLineTwo != null) {
    toReturn.push(address.AddressLineTwo);
  }
  toReturn.push(getAddressCityProvPostalCode(address));
  return toReturn;
}

export function getStreetAddressString(address: AddressV2) {
  let toReturn = '';
  if (address.AddressLineOne != null) {
    toReturn += address.AddressLineOne;
  }
  if (address.AddressLineTwo != null) {
    toReturn += ' ' + address.AddressLineTwo;
  }
  return toReturn;
}

export function getAddressCityProvPostalCode(address: AddressV2): string {
  const stringArray = [address.City, address.ProvinceState, address.PostalZipCode];
  return stringArray.filter((val) => val != null).join(' ');
}
