import { computed, IObservableArray, makeObservable, observable } from 'mobx';
import OnlineBookingCurrency from '../../enums/common/OnlineBookingCurrency';
import CompanyProjectStatus from '../../enums/crm/CompanyProjectStatus';
import CompanyStatus from '../../enums/crm/CompanyStatus';
import { SetStartDate } from '../../utils/helpers/dateHelpers';
import { Address } from '../common/Address';
import AuditModel from '../common/AuditModel';
import FormOfPaymentModel from '../common/FormOfPaymentModel';
import { IBasicCompanyInfoModel } from '../common/Interfaces/IBasicCompanyInfoModel';
import { ICompanyPolicyAndPrefrenceModel } from '../common/Interfaces/ICompanyPolicyAndPreferenceModel';
import { Contact } from '../contacts/Contact';
import EntityFormModel from '../forms/EntityFormModel';
import { Amenity } from '../hotel/Amenity';
import { BranchOfficeModel } from './BranchOfficeModel';
import { CompanyContractModel } from './CompanyContractModel';
import { CompanyPaymentInformationModel } from './CompanyPaymentInformationModel';
import { CompanyProject } from './CompanyProject';
import { PoliciesAndPreferencesCustomFieldModel } from './PoliciesAndPreferencesCustomFieldModel';
import { PreferredHotelModel } from './PreferredHotelModel';
import ReportingRequirement from './ReportingRequirement';
import { UnPreferredHotelModel } from './UnPreferredHotelModel';
import { arraySetData } from 'src/utils/helpers/arrayHelpers';

export class CompanyModel extends EntityFormModel implements IBasicCompanyInfoModel, ICompanyPolicyAndPrefrenceModel {
  @observable
  public Active: boolean;
  @observable
  public Audits: IObservableArray<AuditModel>;
  @observable
  public PreferredPaymentMethods: IObservableArray<FormOfPaymentModel>;
  @observable
  public ReportingRequirements: IObservableArray<ReportingRequirement>;
  @observable
  public PoliciesAndPreferencesCustomFields: IObservableArray<PoliciesAndPreferencesCustomFieldModel>;
  @observable
  public PaymentInformations: IObservableArray<CompanyPaymentInformationModel>;
  @observable
  // @ts-expect-error added by automation
  public ServiceFees: string;
  @observable
  // @ts-expect-error added by automation
  public RoomingPolicy: string;
  @observable
  // @ts-expect-error added by automation
  public AmenityNotes: string;
  @observable
  // @ts-expect-error added by automation
  public ExcludeTravelerFromQuote: boolean;
  @observable
  // @ts-expect-error added by automation
  public ExcludePriceFromItinerary: boolean;
  @observable
  // @ts-expect-error added by automation
  public CompanyNickName: string;
  @observable
  // @ts-expect-error added by automation
  public CheckInRequirements: string;
  @observable
  // @ts-expect-error added by automation
  public CodingRequirements: string;
  @observable
  // @ts-expect-error added by automation
  public ReportRequirements: string;
  @observable
  // @ts-expect-error added by automation
  public ReportingRequirementNotes: string;
  @observable
  // @ts-expect-error added by automation
  public FolioProcedures: string;
  @observable
  // @ts-expect-error added by automation
  public AnnouncementsPolicy: string;
  @observable
  // @ts-expect-error added by automation
  public AuthorizedBookerPolicy: string;
  @observable
  // @ts-expect-error added by automation
  public SendReportingRequirementEmail: boolean;
  @observable
  // @ts-expect-error added by automation
  public ReportingRequirementEmail: string;
  @observable
  public Amenities: Array<Amenity>;
  @observable
  // @ts-expect-error added by automation
  public ApprovalProcess: string;
  @observable
  // @ts-expect-error added by automation
  public ApprovedBookerEmail: boolean;
  @observable
  // @ts-expect-error added by automation
  public VipTravellersPolicy: string;
  @observable
  // @ts-expect-error added by automation
  public TravelPolicy: string;
  @observable
  // @ts-expect-error added by automation
  public InvoicingInformation: string;
  @observable
  // @ts-expect-error added by automation
  public InvoicingEmail: string;
  @observable
  // @ts-expect-error added by automation
  public InfoToHotel: string;
  @observable
  // @ts-expect-error added by automation
  public ShowServiceFeeOnItinerary: boolean;
  @observable
  // @ts-expect-error added by automation
  public Name: string;
  @observable
  // @ts-expect-error added by automation
  public PhoneNumber: string;
  @observable
  // @ts-expect-error added by automation
  public ParentCompany: string;
  @observable
  // @ts-expect-error added by automation
  public Description: string;
  @observable
  // @ts-expect-error added by automation
  public BdmUserId: string;
  @observable
  // @ts-expect-error added by automation
  public AccountManagerUserId: string;
  @observable
  public Address: Address;
  @observable
  // @ts-expect-error added by automation
  public Email: string;
  @observable
  // @ts-expect-error added by automation
  public FaxNumber: string;
  @observable
  public CompanyContacts: IObservableArray<Contact>;
  @observable
  public CompanyProjects: IObservableArray<CompanyProject>;
  @observable
  public Status: CompanyStatus;
  @observable
  // @ts-expect-error added by automation
  public LeadSource: string;
  @observable
  // @ts-expect-error added by automation
  public AnnualSalesVolume: number;
  @observable
  // @ts-expect-error added by automation
  public StartDate: string;
  @observable
  public BranchOffices: IObservableArray<BranchOfficeModel>;
  @observable
  // @ts-expect-error added by automation
  public Website: string;
  @observable
  // @ts-expect-error added by automation
  public LinkedInProfile: string;
  @observable
  // @ts-expect-error added by automation
  public CompanyEmailDomain: string;
  @observable
  // @ts-expect-error added by automation
  public DkNumber: string;
  @observable
  // @ts-expect-error added by automation
  public ItineraryEmail: string;
  @observable
  // @ts-expect-error added by automation
  public EnableGdsSync: boolean;
  @observable
  // @ts-expect-error added by automation
  public Industry: string;
  @observable
  public PreferredHotels: IObservableArray<PreferredHotelModel>;
  @observable
  public UnPreferredHotels: IObservableArray<UnPreferredHotelModel>;
  @observable
  public CompanyContracts: IObservableArray<CompanyContractModel>;
  @observable
  // @ts-expect-error added by automation
  public PaymentNote: string;
  @observable
  // @ts-expect-error added by automation
  public HasDetailedPayment: boolean;
  @observable
  // @ts-expect-error added by automation
  public HasDetailedPaymentReason: string;
  @observable
  // @ts-expect-error added by automation
  public RateCodes: string;
  @observable
  // @ts-expect-error added by automation
  public BranchNumber: string;
  @observable
  // @ts-expect-error added by automation
  public GdsSyncId: string;
  @observable
  // @ts-expect-error added by automation
  public CompanyCardOnFileForCreate: string;
  @observable
  // @ts-expect-error added by automation
  public CompanyCardOnFileExpiryForCreate: string;
  @observable
  // @ts-expect-error added by automation
  public CompanyCardOnFileLastFour: string;
  @observable
  // @ts-expect-error added by automation
  public OnlineBookingCurrency: OnlineBookingCurrency;
  @observable
  // @ts-expect-error added by automation
  public GraspCode: string;
  @observable
  // @ts-expect-error added by automation
  public AttachFolio: boolean;
  @observable
  // @ts-expect-error added by automation
  public AttachItineraryToInvoice: boolean;
  @observable
  // @ts-expect-error added by automation
  public DisableSelection: boolean;
  @observable
  public RestrictGraspCard: boolean | undefined;

  @observable
  // @ts-expect-error added by automation
  public OBTImportantMessage: string;

  @observable
  // @ts-expect-error added by automation
  public ThirdPartyCreditCardLastFour: string;
  @observable
  // @ts-expect-error added by automation
  public ThirdPartyCreditCardExp: string;
  @observable
  // @ts-expect-error added by automation
  public ThirdPartyCreditCardTypeName: string;
  @observable
  // @ts-expect-error added by automation
  public ThirdPartyCreditCardToken: string;
  @observable
  // @ts-expect-error added by automation
  public ThirdPartyBase64s: string[];
  @observable
  // @ts-expect-error added by automation
  public ThirdPartyCreditCardBillingAddress: string;
  @observable
  // @ts-expect-error added by automation
  public ThirdPartyCardHolder: string;
  @observable
  // @ts-expect-error added by automation
  public ThirdPartyPhoneNumber: string;
  @observable
  // @ts-expect-error added by automation
  public ThirdPartyEmail: string;

  @observable
  // @ts-expect-error added by automation
  public ThirdPartyCCInfoRequiresUpdate: boolean;

  public constructor(status: CompanyStatus = CompanyStatus.Lead) {
    super();
    makeObservable(this);
    this.Active = true;
    this.Status = status;
    this.Address = new Address();
    this.CompanyContacts = observable.array(new Array<Contact>());
    this.Amenities = observable.array(new Array<Amenity>());
    this.PreferredPaymentMethods = observable.array(new Array<FormOfPaymentModel>());
    this.CompanyProjects = observable.array(new Array<CompanyProject>());
    this.BranchOffices = observable.array(new Array<BranchOfficeModel>());
    this.ReportingRequirements = observable.array(new Array<ReportingRequirement>());
    this.PoliciesAndPreferencesCustomFields = observable.array(new Array<PoliciesAndPreferencesCustomFieldModel>());
    this.PreferredHotels = observable.array(new Array<PreferredHotelModel>());
    this.UnPreferredHotels = observable.array(new Array<UnPreferredHotelModel>());
    this.PaymentInformations = observable.array(new Array<CompanyPaymentInformationModel>());
    this.CompanyContracts = observable.array(new Array<CompanyContractModel>());
    this.Audits = observable.array(new Array<AuditModel>());
  }

  @computed
  public get ActiveOngoingProjects() {
    return this.GetActiveProjects().filter((x) => x.Status === CompanyProjectStatus.Ongoing);
  }

  @computed
  public get CompanyTravellers() {
    return this.CompanyContacts;
  }

  @computed
  public get BusinessContacts() {
    return this.CompanyContacts.filter((x) => x.BusinessContact);
  }

  @computed
  public get AccountingContacts() {
    return this.CompanyContacts.filter((x) => x.AccountingContact);
  }

  @computed
  public get AccountManagers() {
    return [];
  }

  @computed
  public get ConflictingPreferences() {
    const unprefHotel = this.UnPreferredHotels.map((value) => value.HotelId);
    return this.PreferredHotels.filter((hotel) => unprefHotel.includes(hotel.HotelId));
  }

  public SetData(dbData: any): void {
    super.SetData(dbData, [
      'Address',
      'CompanyProjects',
      'CompanyContacts',
      'Amenities',
      'PreferredPaymentMethods',
      'BranchOffices',
      'PreferredHotels',
      'UnPreferredHotels',
      'PoliciesAndPreferencesCustomFields',
      'PaymentInformations',
      'ReportingRequirements',
      'CompanyContracts',
      'Audits'
    ]);
    this.Address.SetData(dbData.Address);
    arraySetData(this.CompanyContacts, Contact, dbData.CompanyContacts);
    arraySetData(this.CompanyProjects, CompanyProject, dbData.CompanyProjects);
    arraySetData(this.PreferredPaymentMethods, FormOfPaymentModel, dbData.PreferredPaymentMethods);
    arraySetData(this.Amenities, Amenity, dbData.Amenities);
    arraySetData(this.BranchOffices, BranchOfficeModel, dbData.BranchOffices);
    arraySetData(this.ReportingRequirements, ReportingRequirement, dbData.ReportingRequirements);
    arraySetData(this.PreferredHotels, PreferredHotelModel, dbData.PreferredHotels);
    arraySetData(this.UnPreferredHotels, UnPreferredHotelModel, dbData.UnPreferredHotels);
    arraySetData(this.CompanyContracts, CompanyContractModel, dbData.CompanyContracts);
    arraySetData(this.Audits, AuditModel, dbData.Audits);
    arraySetData(
      this.PoliciesAndPreferencesCustomFields,
      PoliciesAndPreferencesCustomFieldModel,
      dbData.PoliciesAndPreferencesCustomFields
    );
    arraySetData(this.PaymentInformations, CompanyPaymentInformationModel, dbData.PaymentInformations);
    SetStartDate(dbData, this);
  }

  public GetActiveProjects = (active: boolean = true) => {
    return this.CompanyProjects.filter((x) => x.Active === active);
  };

  public GetTravellerById = (id: number) => {
    return this.CompanyTravellers.find((x) => x.Id === id);
  };
}
