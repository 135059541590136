import { createSystem, defaultConfig, defineConfig, mergeConfigs } from '@chakra-ui/react';
import { buildBaseTheme } from '@cksoftware/react-base';
import { alertSlotRecipe } from 'src-new/theme/componentsNew/alertRecipe.tsx';
import { menuSlotRecipe } from 'src-new/theme/componentsNew/menuRecipe';

const baseTheme = buildBaseTheme({
  primaryColor: '#f05a29',
  secondaryColor: '#255993',
  tertiaryColor: '#5AB7E8',
  buttons: {
    borderRadius: 8
  }
});

const frontierTheme = defineConfig({
  globalCss: {
    body: {
      // backgroundColor: 'gray.50'
    },
    html: {
      lineHeight: '1.25'
    },
    '*::selection': {
      bg: 'blue.300'
    }
  },
  theme: {
    slotRecipes: {
      menu: menuSlotRecipe,
      alert: alertSlotRecipe
    },
    tokens: {
      colors: {
        WorkflowGroupActionType: {
          None: { value: '#f8d8d8' },
          Status: { value: '#f8d8d8' },
          Ready: { value: '#d8f8db' },
          Assigned: { value: '#d8eaf8' }
        }
      },
      zIndex: {
        sticky: { value: 900 }
      },
      fonts: {
        heading: { value: `'Montserrat Variable', 'sans-serif'` },
        body: { value: `'Montserrat Variable', 'sans-serif'` }
      }
    }
  }
});

const merged = mergeConfigs(defaultConfig, baseTheme, frontierTheme);
export default createSystem(merged);

export const DyslexicTheme = createSystem(
  mergeConfigs(defaultConfig, baseTheme, frontierTheme, {
    globalCss: {
      body: {
        fontFamily: `OpenDyslexic`
      }
    }
  })
);
